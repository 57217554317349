import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Col, Row, Form, Table } from "react-bootstrap";
import EditButton from "../../editbutton/EditButton";
import DeleteButton from "../../deletebutton/DeleteButton";
import * as yup from "yup";
import {
  fanDataByZoneId,
  getsingleDataFAN,
} from "../../../services/getServices";
import { fanadd } from "../../../services/postServices";
import { fanDelete } from "../../../services/deleteServices";
import { fanupdate } from "../../../services/putServices";
import AddButton from "../../Add/AddButton";
import CancelButton from "../../Cancel/CancelButton";

const Fans = ({ zoneId = "" , mapData = [] }) => {
  const [data, setData] = useState(mapData);
  const [page, setPage] = useState(true);
  const initialValues = {
    devicename: "",
    fantype: "",
    subnetid: "",
    deviceid: "",
    channelid: "",
    gear: "",
    // image: "",
  };

  const [singleData, setsingleData] = useState(initialValues);
  // console.log('Zone ID ===>', zoneId);
  const validationSchema = yup.object({
    devicename: yup.string().min(3).required("Please Enter your devicename"),
    fantype: yup.string().required("Please Select Light Type"),
    subnetid: yup.number().min(5).required("Please Enter your Subnetid"),
    deviceid: yup.number().min(5).required("Please Enter your deviceid"),
    channelid: yup.number().min(5).required("Please Enter your channelid"),
  });
  const formik = useFormik({
    initialValues: singleData,
    enableReinitialize: singleData,
    validationSchema,
    onSubmit: (params) => {
      if (singleData?._id !== undefined) {
        fanupdate(params, singleData._id)
          .then((res) => {
            if (res.status === 200) {
              setPage(true);
              setsingleData(initialValues)
            }
          })
          .catch((err) => {
            console.warn("errr", err);
          });
      } else {
        fanadd({ ...params, zoneid: zoneId })
          .then((res) => {
            console.warn(res);
            if (res.status === 200) {
              console.warn(res.data.data);
              formik.resetForm();
              setPage(true);
            } else {
              console.warn();
            }
          })
          .catch((err) => {
            console.warn("dddddddd", err);
          });
      }
    },
  });

  const getFanDataByZoneId = () => {
    fanDataByZoneId(zoneId)
      .then((res) => {
        setData(res.data.data);
        setPage(false);
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  useEffect(() => {
    if (zoneId) {
      getFanDataByZoneId(zoneId);
    } else if (page) {
    }
  }, [page]);

  const singalDetails = (params) => {
    getsingleDataFAN(params)
      .then((res) => {
        setsingleData(res?.data?.data[0]);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const cancelvalue = () => {
    formik.resetForm();
  };

  const handleDelete = (userid, e) => {
    fanDelete(userid);
    setPage(true);
  };
  return (
    <>
      <div>
        <Table striped hover responsive className="mt-5">
          <thead>
            <tr>
              <th>Device Name</th>
              <th>Fan Type</th>
              <th>Subnet Id</th>
              <th>Device Id</th>
              <th>Chanel Id</th>
              <th>Gear</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr onSubmit={formik.handleSubmit}>
              <td>
                <Form.Control
                  type="text"
                  name={"devicename"}
                  placeholder="Enter your Device Name"
                  value={formik?.values?.devicename}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.devicename && formik.touched.devicename ? (
                  <p className="error">{formik.errors.devicename}</p>
                ) : null}
              </td>
              <td>
                <Form.Select
                  type="select"
                  name={"fantype"}
                  value={formik?.values?.fantype}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                >
                  <option>Select Fan</option>
                  <option value="1">Fan1</option>
                  <option value="2">Fan2</option>
                  <option value="3">Fan3</option>
                </Form.Select>
                {formik.errors.fantype && formik.touched.fantype ? (
                  <p className="error">{formik.errors.fantype}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="text"
                  name={"subnetid"}
                  placeholder="Enter your Subnetid"
                  value={formik?.values?.subnetid}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.subnetid && formik.touched.subnetid ? (
                  <p className="error">{formik.errors.subnetid}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="text"
                  name={"deviceid"}
                  placeholder="Enter your Deviceid"
                  value={formik?.values?.deviceid}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.deviceid && formik.touched.deviceid ? (
                  <p className="error">{formik.errors.deviceid}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="text"
                  name={"channelid"}
                  placeholder="Enter your Channe Id"
                  value={formik?.values?.channelid}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.channelid && formik.touched.channelid ? (
                  <p className="error">{formik.errors.channelid}</p>
                ) : null}
              </td>
              <td>
                <Form.Select
                  type="select"
                  name={"gear"}
                  value={formik?.values?.gear}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                >
                  <option>Select</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </Form.Select>
                {formik.errors.gear && formik.touched.gear ? (
                  <p className="error">{formik.errors.gear}</p>
                ) : null}
              </td>
              <td>
              <div className="d-flex">
                    <AddButton type={"submit"} onClick={formik.handleSubmit} />
                    <CancelButton onClick={cancelvalue} />
                </div>
              </td>
            </tr>
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item.devicename}</td>
                    <td>{item.fantype}</td>
                    <td>{item.subnetid}</td>
                    <td>{item.deviceid}</td>
                    <td>{item.channelid}</td>
                    <td>{item.gear}</td>
                    <td>
                      <Row sm={3} className="justify-content-center">
                        <Col>
                          <EditButton onClick={() => singalDetails(item._id)} />
                        </Col>
                        <Col>
                          <DeleteButton
                            onClick={() => handleDelete(item._id)}
                          />
                        </Col>
                      </Row>
                    </td>
                    <td></td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Fans;
