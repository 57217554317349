import React, { useState, useEffect } from "react";
import Maintitle from "../../../components/maintitle/Maintitle";
import CustomButton from "../../../components/button/CustomButton";
import { Card, Col, Row, Table } from "react-bootstrap";
import PaginationNew from "../../../components/pagination/PaginationNew";
import Loader from "../../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { customeruser, distcustomer, distusercust } from "../../../services/getServices";
import EditButton from "../../../components/editbutton/EditButton";
import DeleteButton from "../../../components/deletebutton/DeleteButton";
import { custdelete } from "../../../services/deleteServices";
import Swal from "sweetalert2";
import { saveItem } from "../../../services/localStorage";
import { useSelector } from "react-redux";

const Customeruser = ({ mapData = [] }) => {
  const [data, setData] = useState(mapData);
  const [isLoading, setisLoading] = useState(true);
  const [page, setPage] = useState(true);
  const navigate = useNavigate();

  const Role = useSelector((state) => state?.authentication?.userDetails?.role);
  // console.log("sffffgfgfgfgfgfgfgfgfgfg",Role);

  const getCustomerUser = () => {
    setisLoading(true);
    customeruser()
      .then((res) => {
        // console.warn(res);
        setData(res.data.data);
        setisLoading(false);
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  const getDistcust = () => {
    setisLoading(true);
    distcustomer()
      .then((res) => {
        // console.warn(res);
        setData(res.data.data);
        setisLoading(false);
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  const getDistusercust = () => {
    setisLoading(true);
    distusercust()
      .then((res) => {
        // console.warn(res);
        setData(res.data.data);
        setisLoading(false);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(() => {
    if (Role === 0) {
      getCustomerUser();
    } else if (Role === 2) {
      getDistcust();
    }else if (Role === 3){
      getDistusercust();
    }
  }, [page]);

  const handleDelete = (userid, e) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#483f43",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((change) => {
      if (change) {
        custdelete(userid);
      } else {
        console.log("5895632");
      }
      setPage(true);
    });
  };

  const handleAdmin = (_id) => {
    saveItem("_id", _id);
    navigate("/customeredit/" + `${_id}`);
  };
  return (
    <Row>
      <Loader isLoading={isLoading} />
      <Card className="pt-5">
        <div className=" d-flex justify-content-between px-5">
          <Maintitle heading={"Customer"} textAlign={"center"} />

          <CustomButton
            buttonText={"Add"}
            onClick={() => navigate("/customeradd")}
          />
        </div>
        <Table responsive hover className="mt-3">
          <thead key="thead">
            <tr>
              <th>CustomerID.</th>
              <th>F_Name</th>
              <th>L_Name</th>
              <th>Email</th>
              <th>Phone No.</th>  
              <th>User Name</th>
              <th>Dist_Code</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody key="tbody">
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item?.userid + "."}</td>
                    <td>{item?.firstname}</td>
                    <td>{item?.lastname}</td>
                    <td>{item?.email}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.username}</td>
                    <td>{item?.distributercode}</td>
                    <td>
                      <Row sm={12} className="justify-content-center">
                        <Col sm={3}>
                          <EditButton onClick={() => handleAdmin(item._id)} />
                        </Col>
                        <Col sm={3}>
                          <DeleteButton
                            onClick={() => handleDelete(item._id)}
                          />
                        </Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <PaginationNew />
      </Card>
    </Row>
  );
};

export default Customeruser;
