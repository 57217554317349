import React, { useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useEffect } from "react";
import CustomButton from "../../button/CustomButton";
import {
  OtherdataByZoneId,
  getsingalOtherdata,
} from "../../../services/getServices";
import EditButton from "../../editbutton/EditButton";
import * as yup from "yup";
import { useFormik } from "formik";
import DeleteButton from "../../deletebutton/DeleteButton";
import { controladd } from "../../../services/postServices";
import { controlsDelete } from "../../../services/deleteServices";
import { controlsupdate } from "../../../services/putServices";
import { ErrorTost } from "../../tost/Tostpop";

const Formmm = ({ zoneId = "" }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(true);

  const initialValues = {
    remark: "",
    type: "",
    subnetid: "",
    deviceid: "",
    channelid: "",
    channel1: "",
    channel2: "",
    logicOFF: "",
    logicON: "",
    onimage: "",
    offimage: "",
  };

  // const [value, setValue] = useState([initialValues]);
  const [show, setShow] = useState(false);
  const [singleData, setSingleData] = useState(initialValues);

  const validationSchema = yup.object({
    remark: yup.string().min(5).required("Remark is Required"),
    type: yup.string().required("Please Select Type"),
    deviceid: yup.number().min(2).required("DeviceId is Required"),
    subnetid: yup.number().min(2).required("Subnetid is Required"),
    // channelid: yup.number().min(2).required("channelid is Required"),
    // channel1: yup.number().min(2).required("channel1 is Required"),
    // channel2: yup.number().min(2).required("channel2 is Required"),
    // logicOFF: yup.number().min(2).required("logicOFF is Required"),
    // logicON: yup.number().min(2).required("logicON is Required"),
  });

  const formik = useFormik({
    initialValues: singleData,
    enableReinitialize: singleData,
    validationSchema,
    onSubmit: (params) => {
      if (singleData?._id !== undefined) {
        controlsupdate(params, singleData._id)
          .then((res) => {
            if (res.status === 200) {
              setShow(false);
              setPage(true);
              setSingleData(initialValues);
            }
          })
          .catch((err) => {
            console.warn("ssss", err);
          });
      } else {
        controladd({
          ...params,
          zoneid: zoneId,
          onimage: params.onimage,
          offimage: params.offimage,
        })
          .then((res) => {
            // console.log("hhhh", res);
            if (res.status === 200) {
              // console.log("sss", res.data.data);
              setShow(false);
              formik.resetForm();
              // setSingleData(initialValues);
              setPage(true);
            } else {
              console.warn();
            }
          })
          .catch((err) => {
            console.warn("dddddddd", err);
            ErrorTost()
          });
      }
    },
  });

  // console.log("log", formik.errors);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const getOtherByZoneId = () => {
    OtherdataByZoneId(zoneId)
      .then((res) => {
        setData(res.data.data); 
        setPage(false);
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  useEffect(() => {
    if ((zoneId, page)) {
      getOtherByZoneId(zoneId);
    }
  }, [page, zoneId]);

  const singalDetails = (params, operation) => {
    setShow(true);
    if (operation === "add") {
      setSingleData(initialValues);
    } else if (operation === "edit") {
      getsingalOtherdata(params)
        .then((res) => {
          setSingleData(res?.data?.data[0]);
        })
        .catch((err) => {
          console.warn(err);
        });
    }
  };

  const handleDelete = (userid, e) => {
    controlsDelete(userid);
    setPage(true);
  };

  return (
    <div>
      <div className="d-flex justify-content-center mt-3 ">
        <CustomButton
          buttonText={"ADD"}
          onClick={() => singalDetails("", "add")}
        />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Other Controls</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col>
                <Form.Label as="h6">Remark</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3 mt-3"
                  name={"remark"}
                  placeholder="Enter your remark"
                  value={formik?.values?.remark}
                  onChange={formik.handleChange}
                />
                {formik.errors.remark && formik.touched.remark ? (
                  <p className="error">{formik.errors.remark}</p>
                ) : null}
              </Col>
              <Col>
                <Form.Label as="h6">Select Type</Form.Label>
                <Form.Select
                  type="select"
                  className="mb-3 mt-3"
                  name={"type"}
                  value={formik?.values?.type}
                  onChange={formik.handleChange}
                >
                  <option>Select Type</option>
                  <option value="Single Control">Single Control</option>
                  <option value="InterLock Control">InterLock Control</option>
                  <option value="Logic Control">Logic Control</option>
                </Form.Select>
                {formik.errors.type && formik.touched.type ? (
                  <p className="error">{formik.errors.type}</p>
                ) : null}
              </Col>
              <Row>
                {formik.values.type === "Single Control" && (
                  <Col>
                    <Form.Label as="h6">Channel Id</Form.Label>
                    <Form.Control
                      type="text"
                      className="mb-3 mt-3"
                      name={"channelid"}
                      placeholder="Enter your Channel Id"
                      value={formik?.values?.channelid}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.channelid && formik.touched.channelid ? (
                      <p className="error">{formik.errors.channelid}</p>
                    ) : null}
                  </Col>
                )}
                {formik.values.type === "InterLock Control" && (
                  <Col>
                    <Form.Label as="h6">Channel1</Form.Label>
                    <Form.Control
                      type="text"
                      className="mb-3 mt-3"
                      name={"channel1"}
                      placeholder="Enter your Channel1"
                      value={formik?.values?.channel1}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.channel1 && formik.touched.channel1 ? (
                      <p className="error">{formik.errors.channel1}</p>
                    ) : null}
                  </Col>
                )}

                {formik.values.type === "InterLock Control" && (
                  <Col>
                    <Form.Label as="h6">Channel 2</Form.Label>
                    <Form.Control
                      type="text"
                      className="mb-3 mt-3"
                      name={"channel2"}
                      placeholder="Enter your Channel1"
                      value={formik?.values?.channel2}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.channel2 && formik.touched.channel2 ? (
                      <p className="error">{formik.errors.channel2}</p>
                    ) : null}
                  </Col>
                )}
                <Row>
                  {formik.values.type === "Logic Control" && (
                    <Col>
                      <Form.Label as="h6">Logic OFF</Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-3 mt-3"
                        name={"logicOFF"}
                        placeholder="Enter your Logic Off"
                        value={formik?.values?.logicOFF}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.logicOFF && formik.touched.logicOFF ? (
                        <p className="error">{formik.errors.logicOFF}</p>
                      ) : null}
                    </Col>
                  )}
                  {formik.values.type === "Logic Control" && (
                    <Col>
                      <Form.Label as="h6">Logic ON</Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-3 mt-3"
                        name={"logicON"}
                        placeholder="Enter your Logic On"
                        value={formik?.values?.logicON}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.logicON && formik.touched.logicON ? (
                        <p className="error">{formik.errors.logicON}</p>
                      ) : null}
                    </Col>
                  )}
                </Row>
              </Row>
            </Row>
            <Row>
              <Col>
                <Form.Label as="h6">Subnetid</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3 mt-3"
                  name={"subnetid"}
                  placeholder="Enter your Subnetid"
                  value={formik?.values?.subnetid}
                  onChange={formik.handleChange}
                />
                {formik.errors.subnetid && formik.touched.subnetid ? (
                  <p className="error">{formik.errors.subnetid}</p>
                ) : null}
              </Col>
              <Col>
                <Form.Label as="h6">Deviceid</Form.Label>
                <Form.Control
                  type="number"
                  className="mb-3 mt-3"
                  name={"deviceid"}
                  placeholder="Enter your deviceid"
                  value={formik?.values?.deviceid}
                  onChange={formik.handleChange}
                />
                {formik.errors.deviceid && formik.touched.deviceid ? (
                  <p className="error">{formik.errors.deviceid}</p>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label as="h6">ON Image</Form.Label>
                <Form.Control
                  type="file"
                  className="mb-3 mt-3"
                  name={"onimage"}
                  placeholder="Select your image"
                  value={formik?.file?.onimage}
                  onChange={(e) =>
                    formik.setFieldValue("onimage", e.currentTarget.files[0])
                  }
                />
              </Col>
              <Col>
                <Form.Label as="h6">OFF Image</Form.Label>
                <Form.Control
                  type="file"
                  className="mb-3 mt-3"
                  name={"offimage"}
                  placeholder="Select your image"
                  value={formik?.file2?.offimage}
                  onChange={(e) =>
                    formik.setFieldValue("offimage", e.currentTarget.files[0])
                  }
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <CustomButton onClick={handleClose} buttonText={"Close"} />
          <CustomButton
            buttonText={"ADD"}
            type={"submit"}
            onClick={formik.handleSubmit}
          />
        </Modal.Footer>
      </Modal>
      <Table striped hover responsive className="mt-5">
        <thead>
          <tr>
            <th>Remark</th>
            <th>Type</th>
            <th>SubnetID</th>
            <th>Device ID</th>
            <th>Channel ID</th>
            <th>Channel 1</th>
            <th>Channel 2</th>
            <th>Logic ON</th>
            <th>Logic OFF</th>
            <th>On Image</th>
            <th>OFF Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((item) => {
              {
                /* console.log(item); */
              }
              return (
                <tr>
                  <td>{item.remark}</td>
                  <td>{item.type}</td>
                  <td>{item.subnetid}</td>
                  <td>{item.deviceid}</td>
                  <td>{item.channelid}</td>
                  <td>{item.channel1}</td>
                  <td>{item.channel2}</td>
                  <td>{item.logicON}</td>
                  <td>{item.logicOFF}</td>
                  <td>
                    <img
                      src={item.onimage}
                      alt="default"
                      width="80px"
                      height="50px"
                    />
                  </td>
                  <td>
                    <img
                      src={item.offimage}
                      alt="default"
                      width="80px"
                      height="50px"
                    />
                  </td>
                  <td>
                    <Row sm={12} className="justify-content-center">
                      <Col sm={6}>
                        <EditButton
                          onClick={() => singalDetails(item._id, "edit")}
                        />
                      </Col>
                      <Col sm={6}>
                        <DeleteButton onClick={() => handleDelete(item._id)} />
                      </Col>
                    </Row>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default Formmm;
