import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { GrAdd } from "react-icons/gr";
import { BsPlusSquareFill } from "react-icons/bs";
import { RiAddBoxLine } from "react-icons/ri";

  import { IoAdd } from "react-icons/io5";
  // import { RiAddCircleLine } from "react-icons/ri";

const AddButton = ({ onClick, type }) => {
  const tooltip = (
    <Tooltip id=" tooltip">
      <strong>ADD</strong>
    </Tooltip>
  );
  return (
    <div className="mx-3">
      <OverlayTrigger placement="bottom" overlay={tooltip} delayShow={50}>
        {/* <IoAdd size={25} onClick={onClick} style={{fontWeight:"bold"}} type={type} title="Add"/> */}
        <BsPlusSquareFill Sach size={20} onClick={onClick} style={{fontWeight:"bold"}} type={type} title="Add"/>
        {/* <RiAddCircleLine size={30} onClick={onClick} type={type} title="Add"/> */}
      </OverlayTrigger>
    </div>
  );
};

export default AddButton;
