import { createSlice } from "@reduxjs/toolkit";
import { getAccessToken, getItem, getRefreshToken } from "../services/localStorage";

const accessToken = getAccessToken();
const refreshToken = getRefreshToken();
const userdetails = getItem("userDetails")

// console.log("get accessToken from redux", !!accessToken);
const initialState = {
  //  isLoggedIn: !!Token,
  isLogin: !!accessToken,
  accessToken: accessToken ? accessToken : null,
  refreshToken: refreshToken ? refreshToken : null,
  isProcessingRequest: false,
  isReloadFlag: true,
  isZoneFlage: "",
  userDetails: userdetails ? JSON.parse(userdetails) : null
};

export const authenticationSlice = createSlice({  
  name: "authentication",
  initialState,
  reducers: {
    start: (state) => {
      return {
        ...state,
        isProcessingRequest: true,
      };
    },
    success: (i, state) => {
      // console.log('redux', state.payload);
      return {
        ...i,
        isLogin: true,
        userDetails: state.payload.userDetails,
        accessToken: state.payload.AccessToken,
        refreshToken: state.payload.RefreshToken
      };
    },
    error: (state) => {
      return {
        ...state,
        isProcessingRequest: false,
      };
    },
    macreload: (state, action) => {
      return {
        ...state,
        data: action.payload,
        isReloadFlag: true,
      };
    },

    // loginuser:(state,action) => {
    //   return{
    //     ...state,
    //     userdata : action.payload,
    //     isLogin: true,
    //   };
    // },
    //     zone:(state,action) => {
    //       return{
    //         ...state,
    //         isZoneFlage : action.payload,
    // }
    //  },
    logout: (state) => {
      // localStorage.removeItem("userDetails",JSON.stringify(state.payload));
      return {
        accessToken: "",
        refreshToken: "",
        isLogin: false,
        data: [],
      };
    },
  },
});

export const { start, success, error, logout, loginData, macreload, zone , loginuser } =
  authenticationSlice.actions;
export const isLogin = (RootState) => RootState.authentication.isLogin;
export const ZoneId = (RootState) => RootState.authentication.isZoneFlage;
export const getisReloadFlag = (RootState) => RootState;
export default authenticationSlice.reducer;
