import React, { useEffect, useState } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
// import "./adduser.css";
import { useFormik } from "formik";
import { schema } from "../../schema";
import { custadd } from "../../../../services/postServices";
import { useNavigate, useParams } from "react-router-dom";
import { getsingleDatacustomer } from "../../../../services/getServices";
import { customerupdate } from "../../../../services/putServices";
import Mactable from "../../../../components/MacAdd/Mactable";
import Customerform from "../../../../components/customerform/Customerform";
import Areas from "../../../../components/areas/Areas";
import Zone from "../../../../components/zone/Zone";

const Customeradd = () => {
  const [title, setTitle] = useState(false);
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    distributercode: "",
  };

  const { id } = useParams();
  // console.warn('idddddddd',id);
  const [userData, setUserData] = useState(initialValues);

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: userData,
    enableReinitialize: userData,
    validationSchema: schema,
    onSubmit: (params) => {
      // console.log(schema)
      if (id) {
        customerupdate(params, id)
          .then((res) => {
            navigate("/customeruser");
            setUserData(res.data.data);
          })
          .catch((err) => {
            console.warn(("error data here", err));
          });
      } else {
        custadd(params)
          .then((res) => {
            console.warn(res);
            if (res.status === 200) {
              console.warn(res.data.data);
              navigate("/customeruser");
            } else {
              console.warn();
            }
          })
          .catch((err) => {
            console.warn(err);
          });
      }
    },
  });

  const singleData = () => {
    // const params = id
    getsingleDatacustomer(id)
      .then((res) => {
        setTitle(true);
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.warn("error data here", err);
      });
  };
  
useEffect(() => {
    if (id !== undefined) {
      singleData();
    }
  }, [id]);


  return (
    <div>
    <Card className="mt-5 mb-5">
    <div className="mt-5 mb-5" >
      {id === undefined ? (
          <Customerform
            handleSubmit={formik.handleSubmit}
            formik={formik}
            title={title}
          />
      ) : (
        <>
          <Tabs
            className="mb-3 mt-3"
            id="fill-tab"
            fill
            justify
           >
            <Tab eventKey="home" title="Profile" >
              <Customerform
                handleSubmit={formik.handleSubmit}
                formik={formik}
                title={title}
              />
            </Tab>
            <Tab eventKey="mac" title="Mac Id">
              <Mactable/> 
            </Tab>
            <Tab eventKey="area" title="Areas">
            <Areas/>
            </Tab>
            <Tab eventKey="zone" title="Zone">
            <Zone/>
            </Tab>
          </Tabs>
        </>
      )}
    </div>
    </Card>
    </div>
  );
};

export default Customeradd;
