import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from 'react-bootstrap';
import { useFormik } from "formik";
import * as yup from "yup";
import EditButton from "../../editbutton/EditButton";
import DeleteButton from "../../deletebutton/DeleteButton";
import { getsingleDataMacro, macrodataByZoneId,  } from "../../../services/getServices";
import { macroiradd } from "../../../services/postServices";
import { macroirDelete } from "../../../services/deleteServices";
import { macroirupdate } from "../../../services/putServices";
import AddButton from "../../Add/AddButton";
import CancelButton from "../../Cancel/CancelButton";

const Macroir = ({zoneId = '', mapData =[]}) => {
    const [data, setData] = useState(mapData);
    const [page, setPage] = useState(true);
  
    const initialValues = {
      title: "",
      subnetid: "",
      deviceid: "",
      macroNo: "",
      onimage:"",
      offimage:""
    };
  
    const [singleData, setsingleData] = useState(initialValues);

    const validationSchema = yup.object({
        title: yup.string().min(1).required("Please Enter your Title"),
        subnetid: yup.number().min(5).required("Please Enter your Subnetid"),
        deviceid: yup.number().required("Please Select Deviceid"),
        macroNo: yup.number().min(5).required("Please Enter your MacroNo"),
    });
    
  const formik = useFormik({
    initialValues: singleData,
    enableReinitialize: singleData,
    validationSchema,
    onSubmit: (params) => {
      if(singleData?._id !==undefined){
        macroirupdate(params,singleData._id)
        .then((res) => {
          if(res.status === 200){
            setPage(true);
            setsingleData(initialValues)
          }
        })
        .catch((err) => {
          console.warn("errr",err);
        });
      }else{
      console.warn(params);
      macroiradd({...params, zoneid: zoneId , onimage: params.onimage, offimage: params.offimage})
        .then((res) => {
          if (res.status === 200) {
            console.warn(res.data.data);
            formik.resetForm();
            setPage(true);
          } else {
            console.warn();
          }
        })
        .catch((err) => {
          console.warn("dddddddd", err);
        });
      }
    },
  });

  const getMacroDataByZoneId = () => {
    macrodataByZoneId(zoneId).then((res) => {
      setData(res.data.data);
      setPage(false);
    })
    .catch((err) => {
      console.warn(err);
    });
  };
  useEffect(() => {
    if(zoneId,page){
      getMacroDataByZoneId(zoneId);
    }else if (page) {
    }
  }, [page, zoneId]);

  const singalDetails = (params) =>{
    getsingleDataMacro(params)
    .then((res) => {
      setsingleData(res?.data?.data[0]);
    })
    .catch((err) => {
      console.warn(err);
    })
  };

  const cancelvalue = () => {
    formik.resetForm()
  }

  const handleDelete = (userid) => {
    macroirDelete(userid);
    setPage(true);
  };
  
  return (
    <div>
        <Table striped hover responsive className="mt-5">
          <thead>
            <tr>
              <th>Title</th>
              <th>Subnetid</th>
              <th>Deviceid</th>
              <th>MacroNo</th>
              <th>ON Image</th>
              <th>OFF Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr onSubmit={formik.handleSubmit}>
              <td>
                <Form.Control
                  type="text"
                  name={"title"}
                  placeholder="Enter your Title"
                  value={formik?.values?.title}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.title && formik.touched.title ? (
                  <p className="error">{formik.errors.title}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="number"
                  name={"subnetid"}
                  placeholder="Enter your Subnetid"
                  value={formik?.values?.subnetid}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.subnetid && formik.touched.subnetid ? (
                  <p className="error">{formik.errors.subnetid}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="number"
                  name={"deviceid"}
                  placeholder="Enter your deviceid"
                  value={formik?.values?.deviceid}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.deviceid && formik.touched.deviceid ? (
                  <p className="error">{formik.errors.deviceid}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="number"
                  name={"macroNo"}
                  placeholder="Enter your MacroNo"
                  value={formik?.values?.macroNo}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.macroNo && formik.touched.macroNo ? (
                  <p className="error">{formik.errors.macroNo}</p>
                ) : null}
              </td>
              <td>
                  <Form.Control
                  type="file"
                  name={"onimage"}
                  placeholder="Select your image"
                  value={formik?.file?.values?.onimage}
                  style={{ width: "200px" }}
                  onChange={(e) =>
                    formik.setFieldValue("onimage", e.currentTarget.files[0])
                  }
                />
                </td>
                <td>
                  <Form.Control
                  type="file"
                  name={"offimage"}
                  placeholder="Select your image"
                  value={formik?.file?.values?.offimage}
                  style={{ width: "200px" }}
                  onChange={(e) =>
                    formik.setFieldValue("offimage", e.currentTarget.files[0])
                  }
                />
                  </td>
              <td>
              <div className="d-flex">
                    <AddButton type={"submit"} onClick={formik.handleSubmit} />
                    <CancelButton onClick={cancelvalue} />
                </div>
              </td>
            </tr>
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item.title}</td>
                    <td>{item.subnetid}</td>
                    <td>{item.deviceid}</td>
                    <td>{item.macroNo}</td>
                    <td>
                      <img
                        src={item.onimage}
                        alt="default"
                        width="80px"
                        height="50px"
                      />
                      </td>
                      <td>
                      <img
                        src={item.offimage}
                        alt="default"
                        width="80px"
                        height="50px"
                      />
                      </td>
                    <td>
                      <Row md={7} className="justify-content-center">
                        <Col md={3}>
                          <EditButton onClick={() => singalDetails(item._id)} />
                        </Col>
                        <Col>
                          <DeleteButton onClick={() => handleDelete(item._id)}/>
                        </Col>
                      </Row>
                    </td>
                    <td></td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
    </div>
  )
}

export default Macroir
