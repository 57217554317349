import axios from "axios";
import { BASE_URL } from "../constant/Api";
import {
  getAccessToken,
  removeTokens,
  setAccessToken,
  setRefreshToken,
} from "./localStorage";
import { ErrorTost, SuccessTost } from "../components/tost/Tostpop";

axios.defaults.baseURL = BASE_URL;

export const getItem = (key) => {
  return localStorage.getItem(key);
};

const getHeaderInfo = async function () {
  let token = getAccessToken()
  // console.log('hedaer token api',token); 
  return {
    headers: {
      "Content-Type": "application/json",
      "Authorization" : `Bearer ${token}`,
    },
  };
};
const getHeaderInfoImage = () => {
  return {
      "Content-Type": "multipart/form-data;",
  };
};

const handleResponse = (response) => {
  if (response?.data?.Status === 401 || response?.data?.Status === 403) {
    window.location.href = "/";
    removeTokens();
    // console.warn(window.location);
    ErrorTost(response?.data?.Message);
  }

  if (response?.data.status === 200) {
    // console.warn("api response details",response);
    if (response?.data?.data?.AccessToken !== undefined) {
      setAccessToken(response?.data?.data?.AccessToken);
      setRefreshToken(response?.data?.data?.RefreshToken);
      SuccessTost(response?.data?.message);
    }
  } else {
    ErrorTost(response?.data?.Message);
    // History.push("/");
  }

  if (response?.data?.status === 413) {
    throw response;
  }
  return response;
};
export const post = async function (url, body) {
  
  try {
    let response = await axios.post(url, body);
    return handleResponse(response);
  } catch (err) {
    throw handleResponse(err.response);
  }
};
export const post2 = async function (url, params ={}) {
  let header = getHeaderInfoImage();
  try {
    let response = await axios.post(url, params ,{
      headers : header
    }); 
    return handleResponse(response);
  } catch (err) {
    throw handleResponse(err.response);
  }
};

export const get = async function (url, params = {}) {
  let header = await getHeaderInfo();
  try {
    let resp = await axios.get(url, { params, ...header });
    // SuccessTost(resp?.data?.message);
    return handleResponse(resp);
  } catch (err) {
    ErrorTost(err?.data?.message)
    throw handleResponse(err.response);
  }
};

export const put = async function (url, params) {
  try {
    let resp = await axios.patch(url, params);
    SuccessTost(resp?.data?.message);
    return handleResponse(resp);
  } catch (err) {
    throw handleResponse(err.response);
  }
};
export const put2 = async function (url, params ={}) {
  let header = getHeaderInfoImage();
  try {
    let response = await axios.patch(url, params ,{
      headers : header
    }); 
    return handleResponse(response);
  } catch (err) {
    throw handleResponse(err.response);
  }
};

export const all = async function (url, params = {}) {
  let header = await getHeaderInfo();
  try {
    let resp = await axios.all(url, { ...header, params });
    SuccessTost(resp?.data?.message);
    return handleResponse(resp);
  } catch (err) {
    throw handleResponse(err.response);
  }
};

axios.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ` + token;
    }
    return config;
  },

  () => {
    Promise.reject("Error");
  }
);

export const deleteApi = async function (url) {
  let header = await getHeaderInfo();
  try {
    let resp = await axios.delete(url, { header });
//     Swal.fire({
//       title: 'Are you sure?',
//       // text: "You won't be able to revert this!",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#483f43',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, delete it!'
// })
    SuccessTost(resp.data.message);
      return handleResponse(resp);
     
   
  } catch (err) {
    throw handleResponse(err.response);
  }
};
