import React, { useState ,useEffect } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import * as yup from "yup";
import EditButton from "../../editbutton/EditButton";
import DeleteButton from "../../deletebutton/DeleteButton";
import { useFormik } from "formik";
import { camraDataByZoneId, getsingleDataCamra } from "../../../services/getServices";
import { cameraDelete } from "../../../services/deleteServices";
import { cameradd } from "../../../services/postServices";
import { Camaraupdate } from "../../../services/putServices";
import AddButton from "../../Add/AddButton";
import CancelButton from "../../Cancel/CancelButton";

const Camra = ({zoneId = '', mapData = []}) => {
  const [data, setData] = useState(mapData);
  const [page, setPage] = useState(true);

  const initialValues = {
    Title: "",
    WebURL: "",
    UserName: "",
    Password: "",
    Port: "",
  };
  const [singleData, setsingleData] = useState(initialValues);

  const validationSchema = yup.object({
    Title: yup.string().min(5).required("Please Enter Your Title"),
    WebURL: yup.string().min(5).required("Please Enter Your WebURL"),
    UserName: yup.string().min(5).required("Please Enter Your UserName"),
    Password: yup.string().min(5).required("Please Enter Your Password"),
    Port: yup.string().min(4).required("Please Enter Your Port"),
  });

  const formik = useFormik({
    initialValues: singleData,
    enableReinitialize: singleData,
    validationSchema,
    onSubmit: (params) => {
      if(singleData?._id !==undefined){
        Camaraupdate(params,singleData._id)
        .then((res) => {
          if(res.status === 200){
            setPage(true);
            setsingleData(initialValues)
          }
        })
        .catch((err) => {
          console.warn("errr",err);
        });
      }else{
      cameradd({...params, zoneid: zoneId})
      .then((res) => {
        console.warn(res);
        if (res.status === 200) {
          console.warn(res.data.data);
          formik.resetForm();
          setPage(true);
        } else {
          console.warn();
        }
      })
      .catch((err) => {
        console.warn("dddddddd", err);
      });
    }
    },
  });
  const getCamraDataByZoneId = () => {
    camraDataByZoneId(zoneId).then((res) => {
      setData(res.data.data);
      setPage(false);
    })
    .catch((err) => {
      console.warn(err);
    });
  }
  useEffect(()=>{
    if(zoneId,page){
      getCamraDataByZoneId(zoneId);
      }
    },[page]);

  const singalDetails = (params) =>{
    getsingleDataCamra(params)
    .then((res) => {
      setsingleData(res?.data?.data[0]);
    })
    .catch((err) => {
      console.warn(err);
    })
  }

  const cancelvalue = () => {
    formik.resetForm()
  }
  const handleDelete = (userid) =>{
    cameraDelete(userid);
    setPage(true);
  }
  return (
    <>
      <div>
          <Table striped hover responsive className="mt-5">
            <thead>
              <tr>
                <th>Title</th>
                <th>Web URL</th>
                <th>User Name</th>
                <th>Password</th>
                <th>Port</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr onSubmit={formik.handleSubmit}>
                <td>
                  <Form.Control
                    type="text"
                    name={"Title"}
                    placeholder="Enter your Title"
                    value={formik?.values?.Title}
                    onChange={formik.handleChange}
                    style={{ width: "250px" }}
                  />
                  {formik.errors.Title && formik.touched.Title ? (
                    <p className="error">{formik.errors.Title}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                    type="text"
                    name={"WebURL"}
                    placeholder="Enter your WebURL"
                    value={formik?.values?.WebURL}
                    onChange={formik.handleChange}
                    style={{ width: "250px" }}
                  />
                  {formik.errors.WebURL && formik.touched.WebURL ? (
                    <p className="error">{formik.errors.WebURL}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                    type="text"
                    name={"UserName"}
                    placeholder="Enter your UserName"
                    value={formik?.values?.UserName}
                    onChange={formik.handleChange}
                    style={{ width: "250px" }}
                  />
                  {formik.errors.UserName && formik.touched.UserName ? (
                    <p className="error">{formik.errors.UserName}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                    type="password"
                    name={"Password"}
                    placeholder=" Enter your Password"
                    value={formik?.values?.Password}
                    onChange={formik.handleChange}
                    style={{ width: "250px" }}
                  />
                  {formik.errors.Password && formik.touched.Password ? (
                    <p className="error">{formik.errors.Password}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                    type="text"
                    name={"Port"}
                    placeholder="Enter your Port"
                    value={formik?.values?.Port}
                    onChange={formik.handleChange}
                    style={{ width: "250px" }}
                  />
                  {formik.errors.Port && formik.touched.Port ? (
                    <p className="error">{formik.errors.Port  }</p>
                  ) : null}
                </td>
                <td>
                <div className="d-flex">
                    <AddButton type={"submit"} onClick={formik.handleSubmit} />
                    <CancelButton onClick={cancelvalue} />
                </div>
                </td>
              </tr>
              {data.length > 0 &&
                data.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>{item.Title}</td>
                      <td>{item.WebURL}</td>
                      <td>{item.UserName}</td>
                      <td>{item.Password}</td>
                      <td>{item.Port}</td>
                      <td>
                        <Row sm={3} className="justify-content-center">
                          <Col>
                            <EditButton onClick={() => singalDetails(item._id)} />
                          </Col>
                          <Col>
                            <DeleteButton onClick={() => handleDelete(item._id)} />
                          </Col>
                        </Row>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
      </div>
    </>
  );
};

export default Camra;
