import React, { useState, useEffect } from "react";
import Maintitle from "../../../components/maintitle/Maintitle";
import { Card, Col, Row, Table } from "react-bootstrap";
import Loader from "../../../components/loader/Loader";
import PaginationNew from "../../../components/pagination/PaginationNew";
import CustomButton from "../../../components/button/CustomButton";
import { useNavigate } from "react-router-dom";
import { distributersuser, distributersuserrole, distributersusersingle } from "../../../services/getServices";
import EditButton from "../../../components/editbutton/EditButton";
import DeleteButton from "../../../components/deletebutton/DeleteButton";
import { distributoruserDelete } from "../../../services/deleteServices";
import { useSelector } from "react-redux";


const Distributoruser = ({mapData = []}) => {

  const [data, setData] = useState(mapData);
  const [isLoading, setisLoading] = useState(true);
  const navigate = useNavigate();
  const Role = useSelector((state)=>state?.authentication?.userDetails?.role)
  // console.log("sffffgfgfgfgfgfgfgfgfgfg",Role);  
  
  const getDistriUserData = () => {
    setisLoading(true);
    distributersuser()
    .then((res) => {
      // console.warn("xcvbnm",res.data.data);
      setData(res.data.data);
      setisLoading(false);
    })
    .catch((err) => {
      console.warn(err);
      });
  };

  const getDistriUserDatasingle = () => {
    setisLoading(true);
    distributersusersingle()
    .then((res) => {
      // console.warn("asdfghjkiu7ytgbnjuygvbnjh",res.data.data);
      setData(res.data.data);
      setisLoading(false);
    })
    .catch((err) => {
      console.warn(err);
      });
  };
  const getDistriUserDatarole = () => {
    setisLoading(true);
    distributersuserrole()
    .then((res) => {
      // console.warn("asdfghjkiu7ytgbnjuygvbnjh",res.data.data);
      setData(res.data.data);
      setisLoading(false);
    })
    .catch((err) => {
      console.warn(err);
      });
  };

  useEffect(() => {
    if(Role === 0){
     getDistriUserData();
    }else if (Role === 3){
      getDistriUserDatasingle()
    } else if (Role === 2) {
      getDistriUserDatarole()
    }
  }, []);
 
  
  const handleDelete = (userid, e) => {
    distributoruserDelete(userid);
    window.location.reload(true);
  };
  const handleAdmin = (_id) => {
    navigate("/distributoruseredit/" + `${_id}`);
  };
  return (
    <Row>
      <div>
        <Loader isLoading={isLoading} />
      </div>
      <Card className="pt-5">
        <div className=" d-flex justify-content-between px-5">
          <Maintitle heading={"DistributorUser"} textAlign={"center"} />
          <CustomButton
            buttonText={"Add"}
            onClick={() => navigate("/distributoruseradd")}
          />
        </div>
        <Table hover responsive className="mt-3">
          <thead>
            <tr>
              <th>DistUserID</th>
              <th>F_Name</th>
              <th>L_Name</th>
              <th>Email</th>
              <th>Phone No.</th>
              <th>User Name</th>
              <th>Dist_Code</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <tr key={item?._id}>
                    <td>{item?.userid + "."}</td>
                    <td>{item?.firstname}</td>
                    <td>{item?.lastname}</td>
                    <td>{item?.email}</td>
                    <td>{item?.phone}</td>  
                    <td>{item?.username}</td>
                    <td>{item?.distributercode}</td>
                    <td>
                      <Row sm={12} className="justify-content-center">
                        <Col sm={3}>
                          <EditButton onClick={() => handleAdmin(item._id)} />
                        </Col>
                        <Col sm={3}>
                          <DeleteButton
                            onClick={() => handleDelete(item._id)}
                          />
                        </Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <PaginationNew />
      </Card>
    </Row>
  );
};

export default Distributoruser;
