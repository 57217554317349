import React from "react";
import { Card, Container } from "react-bootstrap";
import Maintitle from "../../components/maintitle/Maintitle";
import CustomButton from "../../components/button/CustomButton";
import "./logout.css";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { removeTokens } from "../../services/localStorage";


const Logout = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const test = () =>{ 
   dispatch(logout(false));
   removeTokens();
   navigate("/");
  }
  return (
    <div className="d-flex justify-content-center align-items-center w-100 " style={{height:"80vh"}}>
      {/* <Container className="mt-5"> */}
        <Card className="p-5 text-center logout">
          <Maintitle heading={"Logout"} textAlign={"center"}></Maintitle>
          <h5 className="pt-4 fw-bold"> Are u sure Logout</h5>
          <div className="mx-5 pt-4">
            <CustomButton buttonText={"Logout"} onClick={test} />
          </div>
        </Card>
      {/* </Container> */}
    </div>
  );
};

export default Logout;
