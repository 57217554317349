import React from "react";
import { Container, Pagination } from "react-bootstrap";
import "./paginationnew.css";

let active = 1;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {" "}
      {number}
    </Pagination.Item>  
    
  );
}

const PaginationNew = ({ page, pageCount, setPage }) => {
  const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1);
  };
  // handle previous/
  const handlePrevios = () => {
    if (page === 1) return page;
    setPage(page - 1);
  };

  return (
    <Container className="mt-3 d-flex justify-content-end">
      <Pagination>
        <Pagination.Prev onClick={handlePrevios} disabled={page === 1} />
        {Array(pageCount)
          .fill(null)
          .map((ele, index) => {
            return (
              
                <Pagination.Item
                key={index}
                  active={page === index + 1 ? true : false}
                  onClick={() => setPage(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
            );
          })}
        <Pagination.Next onClick={handleNext} disabled={page === pageCount} />
      </Pagination>
    </Container>
  );
};

export default PaginationNew;
