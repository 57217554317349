import React from "react";
import {
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import Maintitle from "../maintitle/Maintitle";
import { FaAt, FaUserAlt } from "react-icons/fa";
import {
  BsFillEnvelopeFill,
  BsLockFill,
  BsTelephoneForwardFill,
} from "react-icons/bs";
import { Tb123 } from "react-icons/tb";
import CustomButton from "../button/CustomButton";
import { useNavigate } from "react-router-dom";

const Customerform = ({ formik, handleSubmit, title }) => {
  const navigate = useNavigate();

  const goback = () => {
    navigate("/customeruser");
  };

  return (
    <div>
      <Container className=" d-flex justify-content-center mt-5">
        <Form className="add" onSubmit={formik.handleSubmit} method="POST">
          {title ? (
            <Maintitle heading={"Edit Customer"} textAlign={"center"} />
          ) : (
            <Maintitle heading={"Add Customer"} textAlign={"center"} />
          )}
          <Form>
            <Row>
              <Col>
                <Form.Group
                  className="mb-2 mt-2"
                  onSubmit={formik.handleSubmit}
                >
                  <Form.Label as="h6">First Name</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FaUserAlt color="#4a2125" size={20} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name={"firstname"}
                      placeholder="Enter your First Name "
                      value={formik?.values?.firstname}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.firstname && formik.errors.firstname}
                      {...formik.getFieldProps('firstname')}
                    />
                  </InputGroup>
                  {/* {formik.touched.firstname && formik.errors.firstname ? (
                    <p className="error">{formik.errors.firstname}</p>
                  ) : null} */}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2 mt-2">
                  <Form.Label as="h6">Last Name</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FaUserAlt color="#4a2125" size={20} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name={"lastname"}
                      placeholder=" Enter your Last Name"
                      value={formik?.values?.lastname}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.lastname && formik.errors.lastname}
                      {...formik.getFieldProps('lastname')}
                    />
                  </InputGroup>
                  {/* {formik.errors.lastname && formik.touched.lastname ? (
                    <p className="error">{formik.errors.lastname}</p>
                  ) : null} */}
                </Form.Group>
              </Col>
            </Row>
            <FormGroup className="mb-2">
              <Form.Label as="h6">Email Address</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <BsFillEnvelopeFill color="#4a2125" size={20} />
                </InputGroup.Text>
                <Form.Control
                  type="email"
                  name={"email"}
                  placeholder="Enter your Email Address "
                  value={formik?.values?.email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.email && formik.errors.email}
                      {...formik.getFieldProps('email')}
                />
              </InputGroup>
              {/* {formik.errors.email && formik.touched.email ? (
                <p className="error">{formik.errors.email}</p>
              ) : null} */}
            </FormGroup>
            <Row>
              <Col>
                <Form.Group className="mb-2 mt-2">
                  <Form.Label as="h6">Number</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <BsTelephoneForwardFill color="#4a2125" size={20} />
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      name={"phone"}
                      placeholder="Enter your Phone Number"
                      value={formik?.values?.phone}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.phone && formik.errors.phone}
                      {...formik.getFieldProps('phone')}
                    />
                  </InputGroup>
                  {/* {formik.errors.phone && formik.touched.phone ? (
                    <p className="error">{formik.errors.phone}</p>
                  ) : null} */}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2 mt-2">
                  <Form.Label as="h6">User Name</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FaAt color="#4a2125" size={20} />
                    </InputGroup.Text>
                    <Form.Control
                      type="username"
                      name={"username"}
                      placeholder="UserName"
                      value={formik?.values?.username}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.username && formik.errors.username}
                      {...formik.getFieldProps('username')}
                    />
                  </InputGroup>
                  {/* {formik.errors.username && formik.touched.username ? (
                    <p className="error">{formik.errors.username}</p>
                  ) : null} */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-2 mt-2">
                  <Form.Label as="h6">Password</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <BsLockFill color="#4a2125" size={20}/>
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      name={"password"}
                      placeholder="Enter your Password"
                      value={formik?.values?.password}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.password && formik.errors.password}
                      {...formik.getFieldProps('password')}
                    />
                  </InputGroup>
                  {/* {formik.errors.password && formik.touched.password ? (
                    <p className="error">{formik.errors.password}</p>
                  ) : null} */}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mt-2">
                  <Form.Label as="h6">Distributer code</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <Tb123 color="#4a2125" size={25}/>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name={"distributercode"}
                      placeholder="Enter Distributer code"
                      value={formik?.values?.distributercode}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.distributercode && formik.errors.distributercode}
                      {...formik.getFieldProps('distributercode')}
                    />
                  </InputGroup>
                  {/* {formik.errors.distributercode &&
                  formik.touched.distributercode ? (
                    <p className="error">{formik.errors.distributercode}</p>
                  ) : null} */}
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div className="d-flex justify-content-between">
            <CustomButton buttonText={"Back"} onClick={() => goback()} />
            <CustomButton
              buttonText={"ADD"}
              onClick={formik.handleSubmit}
              type={"submit"}
            />
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default Customerform;
