import {
  UPDATED_ZAUDIO_API,
  UPDATE_AC_API,
  UPDATE_AREA_API,
  UPDATE_CAMERAS_API,
  UPDATE_CURTAINS_API,
  UPDATE_CUSTOMERS_API,
  UPDATE_DISTRIBUTER_API,
  UPDATE_DISTRIBUTER_USER_API,
  UPDATE_FAN_API,
  UPDATE_IRRIGATIONS_API,
  UPDATE_LIGHTS_API,
  UPDATE_MACROS_API,
  UPDATE_MCAID_API,
  UPDATE_OTHERCONTROLS_API,
  UPDATE_RGBWS_API,
  UPDATE_SCENCES_API,
  UPDATE_SEQUENCES_API,
  USER_UPDATE_API,
} from "../constant/Api";
import { put, put2 } from "./api";

export const usreupdate = async (params, id) => {
  return put(USER_UPDATE_API + id, params);
};

export const distupdate = async (params, id) => {
  return put(UPDATE_DISTRIBUTER_API + id, params);
};

export const distuserupdate = async (params, id) => {
  return put(UPDATE_DISTRIBUTER_USER_API + id, params);
};

export const customerupdate = async (params, id) => {
  return put(UPDATE_CUSTOMERS_API + id, params);
};

export const macupdate = async (params, id) => {
  return put(UPDATE_MCAID_API + id, params);
};
export const areaupdate = async (params, id) => {
  return put2(UPDATE_AREA_API + id, params);
};

//Devices

//Light
export const lightupdate = async (params, id) => {
  return put2(UPDATE_LIGHTS_API + id, params);
};

//Fan
export const fanupdate = async (params, id) => {
  return put(UPDATE_FAN_API + id, params);
};

//AC
export const acupdate = async (params, id) => {
  return put(UPDATE_AC_API + id, params);
};

//Curtain
export const Curtainupdate = async (params, id) => {
  return put2(UPDATE_CURTAINS_API + id, params);
};

//camara
export const Camaraupdate = async (params, id) => {
  return put(UPDATE_CAMERAS_API + id, params);
};

//Zaudio
export const Zaudioupdate = async (params, id) => {
  return put(UPDATED_ZAUDIO_API + id, params);
};

//Sence
export const Senceupdate = async (params, id) => {
  return put2(UPDATE_SCENCES_API + id, params);
};

//Sequene
export const sequeneupdate = async (params, id) => {
  return put2(UPDATE_SEQUENCES_API + id, params);
};

//Macroir
export const macroirupdate = async (params, id) => {
  return put2(UPDATE_MACROS_API + id, params);
};
//Rgbw
export const rgbwupdate = async (params, id) => {
  return put(UPDATE_RGBWS_API + id, params);
};
//Irrgstion
export const irriupdate = async (params, id) => {
  return put(UPDATE_IRRIGATIONS_API + id, params);
};

//other controls
export const controlsupdate = async (params, id) => {
  return put2(UPDATE_OTHERCONTROLS_API + id, params);
};