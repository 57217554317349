export const removeTokens = () => {
    localStorage.removeItem('AccessToken');
    localStorage.removeItem('RefreshToken');
}

// export const removeUser = () => {
//     localStorage.removeItem("userDetails");
// }
export const saveItem = (key,value) =>{
    localStorage.setItem(key,value);
}

export const getItem = (key) =>{
   return localStorage.getItem(key);
}
export const removeItem = (key) =>{
   return localStorage.removeItem(key);
}
export const clearAllItem = () =>{
    return localStorage.clear();
}


export const getAccessToken = () => getItem('AccessToken');
export const setAccessToken = (adminToken) => saveItem('AccessToken',adminToken);

export const getRefreshToken = () => getItem('RefreshToken');
export const setRefreshToken = (adminToken) => saveItem('RefreshToken',adminToken);