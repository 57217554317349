import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import NavSidebar from "./components/sidebar/NavSidebar";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/login/Login";
// import {usertable} from "./pages/usertable";
import Adminuser from "./pages/table/adminuser/Adminuser";
import Logout from "./pages/logout/Logout";
import Adduser from "./pages/table/adminuser/form/Adduser";
import Customeruser from "./pages/table/customeruser/Customeruser";
import Distributoruser from "./pages/table/distributoruser/Distributoruser";
import Distributor from "./pages/table/distributor/Distributor";
import { isLogin } from "./redux/authSlice";
import Customeradd from "./pages/table/customeruser/form/Customeradd";
import Distributoradd from "./pages/table/distributor/form/Distributoradd";
import Distributoruseradd from "./pages/table/distributoruser/form/Distributoruseradd";
import Mactable from "./components/MacAdd/Mactable";
import { useSelector } from "react-redux";

function App() {
 const location = useLocation();
 useEffect(() => {}, [location.pathname]);
 const islog = useSelector(isLogin)
//  console.log("asdfghjkl",islog);  
  return (
    <>
      {location.pathname !== "/" || isLogin === true ? (
        <NavSidebar>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/adminuser" element={<Adminuser />} />
            <Route path="/adduser" element={<Adduser/>}/>
            <Route path="/adduseredit/:id" element={<Adduser/>}/>
            <Route path="/distributor" element={<Distributor/>}/>
            <Route path="/distributoradd" element={<Distributoradd/>}/>
            <Route path="/distributoredit/:id" element={<Distributoradd/>}/>
            <Route path="/distributoruser" element={<Distributoruser/>}/>
            <Route path="/distributoruseradd" element={<Distributoruseradd/>}/>
            <Route path="/distributoruseredit/:id" element={<Distributoruseradd/>}/>
            <Route path="/customeruser" element={<Customeruser/>}/>
            <Route path="/customeradd"  element={<Customeradd/>}/>
            <Route path="/customeredit/:id"  element={<Customeradd/>}/>
            <Route path="/customeradd/:id" element={<Mactable/>}/>
            <Route path="/logout" element={<Logout/>} />
          </Routes>
        </NavSidebar>
      ):(
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      </div>

      )}

    </>
  );
}

export default App;
