import React from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import "./login.css";
import Maintitle from "../../components/maintitle/Maintitle";
import CustomButton from "../../components/button/CustomButton";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { admin } from "../../services/postServices";
import * as yup from "yup";
import {  ErrorTost } from "../../components/tost/Tostpop";
import { useDispatch } from "react-redux";
import { loginuser, success } from "../../redux/authSlice";
import { getItem, saveItem } from "../../services/localStorage";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
const dispatch = useDispatch()
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Invalid Email Address")
      .required("Please Enter Email Address"),
    password: yup.string().min(6).required("Please Enter your password"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,

    onSubmit: (params) => {
      // console.log(values);

      admin(params).then((res) => {
        if (res.status === 200) {
          const userData = res.data.data.userDetails
          // console.log("hhhkjhjh",res.data.data.userDetails);
          // SuccessTost(res.data.message);
          dispatch(success(res.data.data))
          saveItem("userDetails",JSON.stringify(userData))
          navigate("/dashboard");
        } else {
          // ErrorTost("Invalid email");
        }
      }).catch((err)=>{
        // console.log('hansha ben bharat bhai',err);
        ErrorTost(err.data.message);
      })
    },
  });
  return (
    <Container fluid>
      <Row>
        <Col sm={12} md={6} className="img"></Col>
        <Col sm={12} md={6}>
          <Form className="form" onSubmit={formik.handleSubmit}>
            <div className="justify-content-center d-flex">
              <img src="./logo8.png" className="logo2" alt="logo" />
            </div>
            <Maintitle heading={"Login"} textAlign={"center"} />

            <Form.Group className="mt-3 mb-3" controlId="formBasicEmail">
              <InputGroup>
                <InputGroup.Text>
                  <MdEmail color="#4a2125" />
                </InputGroup.Text>
                <Form.Control
                  type="email"
                  name={"email"}
                  placeholder="Enter Email Address"
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.errors.email && formik.touched.email ? (
                <p className="error">{formik.errors.email}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-5" controlId="formPlaintextPassword">
              <InputGroup>
                <InputGroup.Text>
                  <RiLockPasswordFill color="#4a2125" />
                </InputGroup.Text>
                <Form.Control
                  type="password"
                  name={"password"}
                  placeholder="Password"
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.errors.password && formik.touched.password ? (
                <p className="error">{formik.errors.password}</p>
              ) : null}
            </Form.Group>
            <div className="text-center">
              <CustomButton
                buttonText={"Login"}
                type={"submit"}
                onClick={formik.handleSubmit}
              />
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
