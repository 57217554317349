import React, { useEffect, useState } from "react";
import {Col, Form, Row, Table } from 'react-bootstrap'
import { useFormik } from 'formik';
import * as yup from "yup"; 
import { curatinDataByZoneId, getsingleDataCurt } from "../../../services/getServices";
import EditButton from "../../editbutton/EditButton";
import { curtainadd } from "../../../services/postServices";
import { CuratainDele } from "../../../services/deleteServices";
import DeleteButton from "../../deletebutton/DeleteButton";
import { Curtainupdate } from "../../../services/putServices";
import AddButton from "../../Add/AddButton";
import CancelButton from "../../Cancel/CancelButton";

const Curtain = ({zoneId = '', mapData = []}) => {

    const [data, setData] = useState(mapData);
    const [page, setPage] = useState(true);

    const initialValues = {
      devicename: "",
      Shadetype: "",
      Mangedby: "",
      HasStop: "",
      HasRotate: "",
      onimage:"",
      offimage:""
    };
    const [singleData, setsingleData] = useState(initialValues);

    const validationSchema = yup.object({
        devicename: yup.string().min(5).required("Please Enter your devicename"),
        Shadetype: yup.string().required("Please Select Shadetype"),
        Mangedby: yup.string().min(4).required("Please Enter your Mangedby"),
        HasStop: yup.number().min(2).required("Please Enter your HasStop"),
        HasRotate : yup.number().min(2).required("Please Enter your HasRotate"),
    
      });
    const formik = useFormik({
      initialValues: singleData,
      enableReinitialize: singleData,
      validationSchema,
        onSubmit: (params) => {
          if(singleData?._id !==undefined){
            Curtainupdate(params,singleData._id)
            .then((res)=>{
              if(res.status === 200){
                setPage(true);
                setsingleData(initialValues)
              }
            })
            .catch((err) => {
               console.warn("errr",err);
            })
          }else{
            curtainadd({...params, zoneid: zoneId, onimage: params.onimage, offimage: params.offimage})
              .then((res) => {
                if (res.status === 200) {
                  console.warn(res.data.data);
                  formik.resetForm()
                  console.log('ka bola tha',singleData);
                  setPage(true);
                } else {
                  console.warn();
                }
              })
              .catch((err) => {
                console.warn("dddddddd", err);
              });
          }
        },
      });

      const getCuratinDataByZoneId = () => {
        curatinDataByZoneId(zoneId).then((res) => {
          setData(res.data.data);
          setPage(false);
        })
        .catch((err) => {
          console.warn(err);
        });
      }
      useEffect(() => {
        if(zoneId,page){
          getCuratinDataByZoneId(zoneId);
        }
      }, [page]);

      const singalDetailsCur = (params) =>{
        getsingleDataCurt(params)
        .then((res)=>{
            setsingleData(res?.data?.data[0]);
        })
        .catch((err) => {
          console.warn("errr",err);
        })
      }

      const cancelvalue = () => {
        formik.resetForm()
      }

  const handleDelete = (userid) =>{
    CuratainDele(userid);
    setPage(true);
  }
  return (
         <div>
          <Table striped hover responsive className="mt-5">
            <thead>
              <tr>
                <th>Device Name</th>
                <th>Shade type</th>
                <th>Manged by</th>
                <th>Has Stope</th>
                <th>Has Rotate</th>
                <th>ON Image</th>
                <th>OFF Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr onSubmit={formik.handleSubmit}>
                <td>
                  <Form.Control
                    type="text"
                    name={"devicename"}
                    placeholder="Enter your Device Name"
                    value={formik?.values?.devicename}
                    onChange={formik.handleChange}
                    style={{ width: "150px" }}
                  />
                  {formik.errors.devicename && formik.touched.devicename ? (
                    <p className="error">{formik.errors.devicename}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Select
                    type="select"
                    name={"Shadetype"}
                    value={formik?.values?.Shadetype}
                    onChange={formik.handleChange}
                    style={{ width: "150px" }}
                  >
                    <option>SelectShadetype </option>
                    <option value="Open left">Open left</option>
                    <option value="Open Right">Open Right</option>
                    <option value="Open Center">Open Center</option>
                    <option value="Roll">Roll</option>
                  </Form.Select>
                  {formik.errors.Shadetype && formik.touched.Shadetype ? (
                    <p className="error">{formik.errors.Shadetype}</p>
                  ) : null}
                </td>
                <td>
                <Form.Select
                    type="select"
                    name={"Mangedby"}
                    value={formik?.values?.Mangedby}
                    onChange={formik.handleChange}
                    style={{ width: "150px" }}
                  >
                    <option>Select Light</option>
                    <option value="IR command">IR command</option>
                    <option value="RSIP">RSIP</option>
                    <option value="Realy">Realy</option>
                    <option value="Pulse">Pulse</option>
                    <option value="4 Channel">4 Channel</option>
                  </Form.Select>
                  {formik.errors.Mangedby && formik.touched.Mangedby ? (
                    <p className="error">{formik.errors.Mangedby}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                    type="text"
                    name={"HasStop"}
                    placeholder="Enter your HasStop"
                    value={formik?.values?.HasStop}
                    onChange={formik.handleChange}
                    style={{ width: "150px" }}
                  />
                  {formik.errors.HasStop && formik.touched.HasStop ? (
                    <p className="error">{formik.errors.HasStop}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                    type="text"
                    name={"HasRotate"}
                    placeholder="Enter your HasRotate"
                    value={formik?.values?.HasRotate}
                    onChange={formik.handleChange}
                    style={{width:"150px"}}
                  />
                  {formik.errors.HasRotate && formik.touched.HasRotate ? (
                    <p className="error">{formik.errors.HasRotate}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                  type="file"
                  name={"onimage"}
                  placeholder="Select your image"
                  value={formik?.file?.onimage}
                  style={{ width: "200px" }}
                  onChange={(e) =>
                    formik.setFieldValue("onimage", e.currentTarget.files[0])
                  }
                />
                </td>
                <td>
                  <Form.Control
                  type="file"
                  name={"offimage"}
                  placeholder="Select your image"
                  value={formik?.file?.offimage}
                  style={{ width: "200px" }}
                  onChange={(e) =>
                    formik.setFieldValue("offimage", e.currentTarget.files[0])
                  }
                />
                  </td>
                <td>
                <div className="d-flex">
                    <AddButton type={"submit"} onClick={formik.handleSubmit} />
                    <CancelButton onClick={cancelvalue} />
                </div>
                </td>
              </tr>
              {data.length > 0 &&
                data.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>{item.devicename}</td>
                      <td>{item.Shadetype}</td>
                      <td>{item.Mangedby}</td>
                      <td>{item.HasStop}</td>
                      <td>{item.HasRotate}</td>
                      <td>
                      <img
                        src={item.onimage}
                        alt="default"
                        width="80px"
                        height="50px"
                      />
                      </td>
                      <td>
                      <img
                        src={item.offimage}
                        alt="default"
                        width="80px"
                        height="50px"
                      />
                      </td>
                      <td>
                        <Row sm={3} className="justify-content-center">
                          <Col>
                            <EditButton onClick={() => singalDetailsCur(item._id)} />
                          </Col>
                          <Col>
                            <DeleteButton  onClick={() => handleDelete(item._id)} />
                          </Col>
                        </Row>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
    </div> 
  )
}

export default Curtain;
