import React from "react";
import { Rings } from "react-loader-spinner";
import "./loader.css";

const Loader = ({ isLoading }) => {
  return (
    <div className={isLoading ? "loader" : "doneLoader"}>
      <div>
        <div>
          <Rings
            height="100"
            width="100"
            color="#4a2125"
            radius="10"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
        <div>
          <h4 className="text-center">Loading...</h4>
        </div>
      </div>
    </div>
  );
};

export default Loader;
