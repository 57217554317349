import React, { useEffect, useState } from "react";
import Model from "../model/Model";
import { Card, Tab, Tabs } from "react-bootstrap";
import "./zone.css";
import { zonealldata } from "../../services/getServices";
import Lights from "../Device/lights/Lights";
import Fans from "../Device/fans/Fans";
import Acs from "../Device/Ac/Acs.js";
import Curtain from "../Device/Curtain/Curtain";
import Camra from "../Device/Camra/Camra";
import Sence from "../Device/Sence/Sence";
import Zaudio from "../Device/Zaudio/Zaudio";
import Sequene from "../Device/Sequene/Sequene";
import Macroir from "../Device/Macroir/Macroir";
import Rgbw from "../Device/RGBW/Rgbw";
import Dmx from "../Device/DMX/Dmx";
import Irrigation from "../Device/Irrigation/Irrigation";
import Formmm from "../Device/Othercontrols/Othercontrols";

const Zone = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(true)

  const getZonedata = () => {
    zonealldata()
      .then((res) => {
        // console.warn("ZonecDAta", res.data.data);
        setData(res.data.data);
        setPage(true)
        // console.warn("jjjj", res.data.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  useEffect(() => {
    getZonedata();
  }, [page]);
  // console.warn("hhh", data);
  return (
    <div className="zone">
      <Card className="mt-5 mb-2">
        <Tabs justify  >
          <Tab title={<Model />}></Tab>
          {data.length > 0 &&
            data.map((item) => {
              return (
                <Tab
                  eventKey={item?.zoneid}
                  title={item?.title}
                  key={item._id}
                  className="mb-0"
                >
                  <Tabs className="zone" justify>
                    <Tab eventKey="ac" title="AC">
                      <Acs zoneId={item._id}/>
                    </Tab>
                    <Tab eventKey="curatain" title="Curatain">
                      <Curtain zoneId={item._id} />
                    </Tab>
                    <Tab eventKey="camra" title="Camera">
                      <Camra zoneId={item._id} />
                    </Tab>
                    <Tab eventKey="dmx" title="DMX">
                      <Dmx zoneId={item._id} />
                    </Tab>
                    <Tab eventKey="fan" title="Fan">
                      <Fans zoneId={item._id} />
                    </Tab>
                    <Tab eventKey="Irrigation" title="Irrigation">
                      <Irrigation zoneId={item._id} />
                    </Tab>
                    <Tab eventKey="light" title="Light">
                      <Lights zoneId={item._id} />
                    </Tab>
                    <Tab eventKey="macroir" title="Macroir">
                      <Macroir zoneId={item._id} />
                    </Tab>
                    <Tab eventKey="rgbw" title="RGBW">
                      <Rgbw zoneId={item._id} />
                    </Tab>
                    <Tab eventKey="scence" title="Sence">
                      <Sence zoneId={item._id} />
                    </Tab>
                    <Tab eventKey="sequence" title="Sequence">
                      <Sequene zoneId={item._id} />
                    </Tab>
                    <Tab eventKey="zaudio" title="zaudio">
                      <Zaudio zoneId={item._id} />
                    </Tab>
                    <Tab eventKey="OtherControls" title="Othercontrols">
                      <Formmm zoneId={item._id} />
                    </Tab>
                  </Tabs>
                </Tab>
              );
            })}
        </Tabs>
      </Card>
    </div>
  );
};

export default Zone;
