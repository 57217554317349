import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import EditButton from "../../editbutton/EditButton";
import DeleteButton from "../../deletebutton/DeleteButton";
import { sequenceadd } from "../../../services/postServices";
import { getsingleDataSeques, sequedataByZoneId, sequenceData } from "../../../services/getServices";
import { sequenceDelete } from "../../../services/deleteServices";
import { sequeneupdate } from "../../../services/putServices";
import AddButton from "../../Add/AddButton";
import CancelButton from "../../Cancel/CancelButton";

const Sequene = ({zoneId = '', mapData=[]}) => {
  const [data, setData] = useState(mapData);
  const [page, setPage] = useState(true);

  const initialValues = {
    Remark: "",
    Subnetid: "",
    Deviceid: "",
    AreaNo: "",
    SequenceNo: "",
    onimage:"",
    offimage:""
  };

  const [singleData, setsingleData] = useState(initialValues);

  const validationSchema = yup.object({
    Remark: yup.string().min(5).required("Please Enter your Remark"),
    Subnetid: yup.number().min(5).required("Please Enter your Subnetid"),
    Deviceid: yup.number().required("Please Select Deviceid"),
    AreaNo: yup.number().min(15).required("Please Enter your AreaNo"),
    SequenceNo: yup.number().min(5).required("Please Enter your SequenceNo"),
  });

  const formik = useFormik({
    initialValues: singleData,
    enableReinitialize: singleData,
    validationSchema,
    onSubmit: (params) => {
      if(singleData?._id !==undefined){
        sequeneupdate(params,singleData._id)
        .then((res) => {
          if(res.status === 200){
            setPage(true);
            setsingleData(initialValues)
          }
        })
        .catch((err) => {
          console.warn("errr",err);
        });
      }else{
      console.warn(params);
      sequenceadd({...params, zoneid: zoneId, onimage: params.onimage, offimage: params.offimage})
        .then((res) => {
          if (res.status === 200) {
            console.warn(res.data.data);
            formik.resetForm(initialValues);
            setPage(true);
          } else {
            console.warn();
          }
        })
        .catch((err) => {
          console.warn("dddddddd", err);
        });
    }
  }
  });
  // const getSequencedata = () => {
  //   sequenceData()
  //     .then((res) => {
  //       setData(res.data.data);
  //       setPage(false);
  //     })
  //     .catch((err) => {
  //       console.warn(err);
  //     });
  // };

  const getSeqDataByZoneId = () => {
    sequedataByZoneId(zoneId).then((res) => {
      setData(res.data.data);
      setPage(false);
    })
    .catch((err) => {
      console.warn(err);
    });
  };

  useEffect(() => {
    if(zoneId,page){
      getSeqDataByZoneId(zoneId)
    }else if (page) {
      // getSequencedata();
    }
  }, [page,zoneId]);

  
  const singalDetails = (params) =>{
    getsingleDataSeques(params)
    .then((res) => {
      setsingleData(res?.data?.data[0]);
    })
    .catch((err) => {
      console.warn(err);
    })
  }

  const cancelvalue = () => {
    formik.resetForm()
  }
  
  const handleDelete = (userid) => {
    sequenceDelete(userid);
    setPage(true);
  };
  return (
    <div>
        <Table striped hover responsive className="mt-5">
          <thead>
            <tr>
              <th>Remark</th>
              <th>Subnetid</th>
              <th>Deviceid</th>
              <th>AreaNo</th>
              <th>SequenceNo</th>
              <th>ON Image</th>
              <th>OFF Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr onSubmit={formik.handleSubmit}>
              <td>
                <Form.Control
                  type="text"
                  name={"Remark"}
                  placeholder="Enter your Remark"
                  value={formik?.values?.Remark}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.Remark && formik.touched.Remark ? (
                  <p className="error">{formik.errors.Remark}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="number"
                  name={"Subnetid"}
                  placeholder="Enter your Subnetid"
                  value={formik?.values?.Subnetid}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.Subnetid && formik.touched.Subnetid ? (
                  <p className="error">{formik.errors.Subnetid}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="number"
                  name={"Deviceid"}
                  placeholder="Enter your deviceid"
                  value={formik?.values?.Deviceid}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.Deviceid && formik.touched.Deviceid ? (
                  <p className="error">{formik.errors.Deviceid}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="number"
                  name={"AreaNo"}
                  placeholder="Enter your AreaNo"
                  value={formik?.values?.AreaNo}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.AreaNo && formik.touched.AreaNo ? (
                  <p className="error">{formik.errors.AreaNo}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="number"
                  name={"SequenceNo"}
                  placeholder="Enter your SequenceNo"
                  value={formik?.values?.SequenceNo}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.SequenceNo && formik.touched.SequenceNo ? (
                  <p className="error">{formik.errors.SequenceNo}</p>
                ) : null}
              </td>
              <td>
                  <Form.Control
                  type="file"
                  name={"onimage"}
                  placeholder="Select your image"
                  value={formik?.file?.onimage}
                  style={{ width: "200px" }}
                  onChange={(e) =>
                    formik.setFieldValue("onimage", e.currentTarget.files[0])
                  }
                />
                </td>
                <td>
                  <Form.Control
                  type="file"
                  name={"offimage"}
                  placeholder="Select your image"
                  value={formik?.file?.offimage}
                  style={{ width: "200px" }}
                  onChange={(e) =>
                    formik.setFieldValue("offimage", e.currentTarget.files[0])
                  }
                />
                  </td>
              <td>
              <div className="d-flex">
                    <AddButton type={"submit"} onClick={formik.handleSubmit} />
                    <CancelButton onClick={cancelvalue} />
                </div>
              </td>
            </tr>
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item.Remark}</td>
                    <td>{item.Subnetid}</td>
                    <td>{item.Deviceid}</td>
                    <td>{item.AreaNo}</td>
                    <td>{item.SequenceNo}</td>
                    <td>
                      <img
                        src={item.onimage}
                        alt="default"
                        width="80px"
                        height="50px"
                      />
                      </td>
                      <td>
                      <img
                        src={item.offimage}
                        alt="default"
                        width="80px"
                        height="50px"
                      />
                      </td>
                    <td>
                      <Row sm={3} className="justify-content-center">
                        <Col>
                          <EditButton onClick={ () => singalDetails(item._id)} />
                        </Col>
                        <Col>
                          <DeleteButton
                            onClick={() => handleDelete(item._id)}
                          />
                        </Col>
                      </Row>
                    </td>
                    <td></td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
    </div>
  );
};

export default Sequene;
