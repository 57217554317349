import React from "react";
import Maintitle from "../../components/maintitle/Maintitle";
import { getAccessToken } from "../../services/localStorage";
import { useSelector } from "react-redux";
import { isLogin } from "../../redux/authSlice";

const Dashboard = () => {
  let data = getAccessToken();
  // console.log ("dashboard data here", data);
  const islog = useSelector(isLogin)
  // console.log("asdfghjkl",islog);
  return (
    <div>
      <Maintitle heading={"Coming soon"} textAlign={"center"} />
    </div>
  );
};

export default Dashboard;
  