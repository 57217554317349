import { AC_DELETE_API, ADMIN_USER_DELETE_API, CAMERA_DELETE_API, CURATAIN_DELETE_API, CUSTOMERS_USER_DELETE_API, DELETE_AREA_API, DISTRIBUTERUSER_DELETE_API, DISTRIBUTER_DELETE_API, DMX_DELETE_API, FAN_DELETE_API, IRRIGATION_DELETE_API, LIGHTS_DELETE_API, MACDETAIL_DELETE_API, MACROIR_DELETE_API, OTHERCONTROLS_DELETE_API, RGBW_DELETE_API, SEQUENCE_DELETE_API } from "../constant/Api";
import { deleteApi } from "./api";

//AdminUser delete
export const deleteUser = async (id) => {
  return deleteApi(ADMIN_USER_DELETE_API + id);
};

//Distributor delete
export const distributorDelete = async (id) => {
  return deleteApi(DISTRIBUTER_DELETE_API + id);
};
//DistributorUser delete
export const distributoruserDelete = async (id) => {
  return deleteApi(DISTRIBUTERUSER_DELETE_API + id);
};

//CustomerUser delete
export const custdelete = async (id) => {
  return deleteApi(CUSTOMERS_USER_DELETE_API + id);
};

//CustMac
export const custmacDelete = async (id) => {
  return deleteApi( MACDETAIL_DELETE_API + id);
}

//Area
export const areaDelete = async (id) => {
  return deleteApi( DELETE_AREA_API + id);
}
// Deviec 

//Lights
export const lightDelete = async (id) => { 
  return deleteApi(LIGHTS_DELETE_API + id);
}

//Fan
export const fanDelete= async (id) => { 
  return deleteApi(FAN_DELETE_API + id);
}

//AC
export const acDelete = async (id) => { 
  return deleteApi(AC_DELETE_API + id);
}

//Curtain
export const CuratainDele = async (id) => {
  return deleteApi(CURATAIN_DELETE_API + id);
}

//Camra
export const cameraDelete = async (id) => {
  return deleteApi (CAMERA_DELETE_API + id );
}

//scence
export const scenceDelete = async (id) => {
  return deleteApi (CAMERA_DELETE_API + id );
}

//Zaudio

//scence
export const sequenceDelete = async (id) => {
  return deleteApi (SEQUENCE_DELETE_API + id );
}

//MACROIR
export const macroirDelete = async (id) => {
  return deleteApi (MACROIR_DELETE_API + id );
}

//RGBW
export const rgbwDelete = async (id) => {
  return deleteApi (RGBW_DELETE_API + id );
}

//DMX
export const dmxDelete = async (id) => {
  return deleteApi (DMX_DELETE_API + id );
}

//Irrigation
export const irriDelete = async (id) => {
  return deleteApi (IRRIGATION_DELETE_API + id );
}

//other controls
export const controlsDelete = async (id) => {
  return deleteApi (OTHERCONTROLS_DELETE_API + id );
}
