import React, { useState, useEffect } from "react";
import Loader from "../../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import Maintitle from "../../../components/maintitle/Maintitle";
import CustomButton from "../../../components/button/CustomButton";
import { Card, Col, Row, Table } from "react-bootstrap";
import PaginationNew from "../../../components/pagination/PaginationNew";
import { distributers, distributersingle } from "../../../services/getServices";
import EditButton from "../../../components/editbutton/EditButton";
import DeleteButton from "../../../components/deletebutton/DeleteButton";
import { distributorDelete } from "../../../services/deleteServices";
import { useSelector } from "react-redux";

const Distributor = ({ mapData = [] }) => {
  const [data, setData] = useState(mapData);
  const [isLoading, setisLoading] = useState(true);
  const navigate = useNavigate();

  const Role = useSelector((state)=>state?.authentication?.userDetails?.role)
  // console.log("user ROOOOOOOLL id ",Role)

  const getDistriData = () => {
    setisLoading(true);
    distributers()
      .then((res) => {
        // console.warn("asdfghjklgggggggg", res.data.data);
        setData(res.data.data);
        setisLoading(false);
      })
      .catch((err) => {
        console.warn(err);
        // ErrorTost(err.data.message);
      });
  };
  const getDistrisingal = () => {
    setisLoading(true);
    distributersingle()
    .then((res) => {
      // console.warn("asdfghjklgggggggg",res);
        setData(res.data.data);
        setisLoading(false);
      })
      .catch((err) => {
        console.warn(err);
        // ErrorTost(err.data.message);
      });
  };
  useEffect(() => {
    // console.log();
    if (Role === 0) {
      getDistriData();
    } else if(Role === 2) {
      getDistrisingal();
    } 
  }, []);

  const handleDelete = (userid, e) => {
    distributorDelete(userid);
    // SuccessTost(message);
    window.location.reload(true);
  };
  const handleAdmin = (_id) => {
    navigate("/distributoredit/" + `${_id}`);
  };

  return (
    <Row>
      <div>
        <Loader isLoading={isLoading} />
      </div>
      {/* <Col>
        <Card>
        <div className="d-flex justify-content-between px-5">
          <Maintitle heading={"Distributor"} textAlign={"center"} />
          <CustomButton
            buttonText={"Add"}
            onClick={() => navigate("/distributoradd")}
          />
        </div>
        </Card>
      </Col> */}
      <Card className="pt-5">
      <div className="d-flex justify-content-between px-5">
          <Maintitle heading={"Distributor"} textAlign={"center"} />
          <CustomButton
            buttonText={"Add"}
            onClick={() => navigate("/distributoradd")}
          />
        </div>
        <Table hover responsive className="table">
          <thead className="">
            <tr>
              <th>ID</th>
              <th>F_Name</th>
              <th>L_Name</th>
              <th>Email</th>
              <th>Phone No.</th>
              <th>User Name</th>
              <th>D_code(SG)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <tr key={item?._id}>
                    <>
                      <td>{item?.userid + "."}</td>
                      <td>{item?.firstname}</td>
                      <td>{item?.lastname}</td>
                      <td>{item?.email}</td>
                      <td>{item?.phone}</td>
                      <td>{item?.username}</td>
                      <td>{item?.distributercode}</td>
                      <td>
                        <Row sm={12} className="justify-content-center">
                          <Col sm={3}>
                            <EditButton onClick={() => handleAdmin(item._id)} />
                          </Col>
                          <Col sm={3}>
                            <DeleteButton
                              onClick={() => handleDelete(item._id)}
                            />
                          </Col>
                        </Row>
                      </td>
                    </>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <PaginationNew />
        {/* <PaginationNew pageCount={3} setPage={setPage} page={page} /> */}
      </Card>
    </Row>
  );
};

export default Distributor;
