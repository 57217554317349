import React, { useEffect, useState } from "react";
import { Formik, useFormik } from "formik";
import { Col, Form, Row, Table } from "react-bootstrap";
import * as yup from "yup";
import {
  getsingleDatalight,
  lightDataByZoneId,
  lightalldata,
} from "../../../services/getServices";
import EditButton from "../../editbutton/EditButton";
import DeleteButton from "../../deletebutton/DeleteButton";
import { lightsadd } from "../../../services/postServices";
import { lightDelete } from "../../../services/deleteServices";
import { lightupdate } from "../../../services/putServices";
import AddButton from "../../Add/AddButton";
import CancelButton from "../../Cancel/CancelButton";

const Lights = ({ zoneId = "" , mapData=[]}) => {
  const [data, setData] = useState(mapData); 
  const [page, setPage] = useState(true);

  const initialValues = {
    // zoneid:"", 
    devicename: "",
    lighttype: "",
    subnetid: "",
    deviceid: "",
    channelid: "",
    onimage: "",
    offimage: "",
  };
  const [singleData, setSingleData] = useState(initialValues);

  // console.log('Zone ID ===>', zoneId);
  const validationSchema = yup.object({
    devicename: yup.string().min(1).required("Please Enter your devicename"),
    lighttype: yup.string().required("Please Select Light Type"),
    subnetid: yup.number().min(1).required("Please Enter your Subnetid"),
    deviceid: yup.number().min(1).required("Please Enter your deviceid"),
    channelid: yup.number().min(1).required("Please Enter your channelid"),
  });
  const formik = useFormik({
    initialValues: singleData,
    enableReinitialize: singleData,
    validationSchema,
    onSubmit: (params) => {
      if (singleData?._id !== undefined) {
        lightupdate(params, singleData._id)
          .then((res) => {
            if (res.status === 200) {
              setPage(true);
              setSingleData(initialValues);
            }
          })
          .catch((err) => {
            console.warn("errr", err);
          });
        } else {
          lightsadd({
            ...params,
            zoneid: zoneId,
            onimage: params.onimage,
            offimage: params.offimage,  
          })
          .then((res) => {
            console.warn(res);
            if (res.status === 200) {
              console.warn(res.data.data);
              formik.resetForm();
              setSingleData(initialValues);
              setPage(true);
            } else {
              console.warn();
            }
          })
          .catch((err) => {
            console.warn("dddddddd", err);
          });
      }
    },
  });

  //  const getlightdata = (params = '') => {
  //   lightalldata(params)
  //     .then((res) => {
  //       setData(res.data.data);
  //       setPage(false);
  //     })
  //     .catch((err) => {
  //       console.warn(err);
  //     });
  // };

  const getLightDataByZoneId = () => {
    lightDataByZoneId(zoneId)
      .then((res) => {
        setData(res.data.data);
        setPage(false);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(() => {
    if ((zoneId, page)) {
      getLightDataByZoneId(zoneId);
    } else if (page) {
      // getlightdata();
    }
  }, [page, zoneId]);

  const singalDetails = (params) => {
    getsingleDatalight(params)
      .then((res) => {
        setSingleData(res?.data?.data[0]);
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  // console.log('formik values', formik?.file?.onimage);
  const cancelvalue = () => {
    formik.resetForm()
  }
  const handleDelete = (userid, e) => {
    lightDelete(userid);
    setPage(true);
  };

  return (
    <>
      <div>
        {/* <Card className="mt-5"> */}
        <Table striped hover responsive className="mt-5">
          <thead>
            <tr>
              {/* <th>Zone ID</th> */}
              <th>Device Name</th>
              <th>Light Type</th>
              <th>Subnet Id</th>
              <th>Device Id</th>
              <th>Chanel Id</th>
              <th>ON Image</th>
              <th>OFF Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr onSubmit={formik.handleSubmit}>
              {/* <td></td> */}
              <td>
                <Form.Control
                  type="text"
                  name={"devicename"}
                  placeholder="Enter your Device Name"
                  value={formik?.values?.devicename}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.devicename && formik.touched.devicename ? (
                  <p className="error">{formik.errors.devicename}</p>
                ) : null}
              </td>
              <td>
                <Form.Select
                  type="select"
                  name={"lighttype"}
                  value={formik?.values?.lighttype}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                >
                  <option>Select Light</option>
                  <option value="Non-Dimmabele">Non-Dimmabele</option>
                  <option value="Dimmable">Dimmable</option>
                  <option value="LED">LED</option>
                </Form.Select>
                {formik.errors.lighttype && formik.touched.lighttype ? (
                  <p className="error">{formik.errors.lighttype}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="text"
                  name={"subnetid"}
                  placeholder="Enter your Subnetid"
                  value={formik?.values?.subnetid}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.subnetid && formik.touched.subnetid ? (
                  <p className="error">{formik.errors.subnetid}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="text"
                  name={"deviceid"}
                  placeholder="Enter your Deviceid"
                  value={formik?.values?.deviceid}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.deviceid && formik.touched.deviceid ? (
                  <p className="error">{formik.errors.deviceid}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="text"
                  name={"channelid"}
                  placeholder="Enter your Channe Id"
                  value={formik?.values?.channelid}
                  onChange={formik.handleChange}
                  style={{ width: "150px" }}
                />
                {formik.errors.channelid && formik.touched.channelid ? (
                  <p className="error">{formik.errors.channelid}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="file"
                  name={"onimage"}
                  placeholder="Select your image"
                  value={formik?.file?.onimage}
                  style={{ width: "200px" }}
                  onChange={(e) =>
                    formik.setFieldValue("onimage", e.currentTarget.files[0])
                  }
                />
              </td>
              <td>
                <Form.Control
                  type="file"
                  name={"offimage"}
                  placeholder="Select your image"
                  value={formik?.file2?.offimage}
                  style={{ width: "200px" }}
                  onChange={(e) =>
                    formik.setFieldValue("offimage", e.currentTarget.files[0])
                  }
                />
              </td>
              <td>
                <div className="d-flex">
                    <AddButton type={"submit"} onClick={formik.handleSubmit} />
                    <CancelButton onClick={cancelvalue} />
                </div>
              </td>
            </tr>
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <tr key={item._id}>
                    {/* <td>{item.zoneid}</td> */}
                    <td>{item.devicename}</td>
                    <td>{item.lighttype}</td>
                    <td>{item.subnetid}</td>
                    <td>{item.deviceid}</td>
                    <td>{item.channelid}</td>
                    <td>
                      <img
                        src={item.onimage}
                        alt="default"
                        width="80px"
                        height="50px"
                      />
                      </td>
                      <td>
                      <img
                        src={item.offimage}
                        alt="default"
                        width="80px"
                        height="50px"
                      />
                      </td>
                    <td>
                      <Row sm={3} className="justify-content-center">
                        <Col>
                          <EditButton onClick={() => singalDetails(item._id)} />
                        </Col>  
                        <Col>
                          <DeleteButton
                            onClick={() => handleDelete(item._id)}
                          />
                        </Col>
                      </Row>  
                    </td>
                    <td></td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {/* </Card> */}
      </div>
    </>
  );
};

export default Lights;
