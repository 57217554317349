import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import EditButton from "../../editbutton/EditButton";
import DeleteButton from "../../deletebutton/DeleteButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { getsingleDataIrri,irrigationdataByZoneId } from "../../../services/getServices";
import { irriDelete } from "../../../services/deleteServices";
import { irradd } from "../../../services/postServices";
import { irriupdate } from "../../../services/putServices";
import AddButton from "../../Add/AddButton";
import CancelButton from "../../Cancel/CancelButton";

const Irrigation = ({zoneId ="" , mapData=[]}) => {
  const [data, setData] = useState(mapData);
  const [page, setPage] = useState(true);

  const initialValues = {
    deviceid: "",
    devicename: "",
    subnetid: "",
    channelid: "",
    Isautomatic: "",
    startTime: "",
    EndTime: "",
    DayofWeek: "",
  };

  const [singleData, setsingleData] = useState(initialValues);

  const validationSchema = yup.object({
    deviceid: yup.number().min(5).required("Please Enter your deviceid"),
    devicename: yup.string().min(5).required("Please Enter your devicename"),
    subnetid: yup.number().min(5).required("Please Enter your Subnetid"),
    channelid: yup.number().min(5).required("Please Enter your channelid"),
    startTime:yup.string().required("Strattime is required"),
    EndTime:yup.string().required("EndTime is required"),
    DayofWeek: yup.string().required("Please Select DayofWeek"),
  });
  const formik = useFormik({
    initialValues: singleData,
    enableReinitialize: singleData,
    validationSchema,
    onSubmit: (params) => {
      if(singleData?._id !==undefined){
        irriupdate(params,singleData._id)
        .then((res) => {
          console.warn(res.data.data.DayofWeek);
          if(res.status === 200){
            console.log(res.data.data);
            setPage(true);
            setsingleData(initialValues)
          }
        })
        .catch((err) => {
          console.warn("errr",err);
        });
      }else{
        irradd({...params, zoneid: zoneId})
          .then((res) => {
            console.warn(res.data.data.DayofWeek);
            if (res.status === 200) {
              console.warn(res.data.data);
              formik.resetForm();
              setPage(true);
            } else {
              console.warn();
            }
          })
          .catch((err) => {
            console.warn("dddddddd", err);
          });
        }
    },
  });

    const getIrrigaDataByZoneId = () => {
      irrigationdataByZoneId(zoneId).then((res) => {
        setData(res.data.data);
        setPage(false);
      })
      .catch((err) => {
        console.warn(err);
      });
    };
    useEffect(() => {
      if(zoneId,page){
        getIrrigaDataByZoneId()
      } else if (page) {
      }
    }, [page, zoneId]);

    
  const singalDetails = (params) =>{
    getsingleDataIrri(params)
    .then((res) => {
      setsingleData(res?.data?.data[0]);
    })
    .catch((err) => {
      console.warn(err);
    })
  };

  const cancelvalue = () => {
    formik.resetForm();
  }

    const handleDelete = (userid, e) => {
      irriDelete(userid);
      setPage(true);
    };
  return (
    <>
      <div>
          <Table striped hover responsive className="mt-5">
            <thead>
              <tr>
                <th>Device Id</th>
                <th>Device Name</th>
                <th>Subnet Id</th>
                <th>Chanel Id</th>
                <th>Isautomatic</th>
                <th>startTime</th>
                <th>EndTime</th>
                <th>Dayofweek</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr onSubmit={formik.handleSubmit}>
                <td>
                  <Form.Control
                    type="text"
                    name={"deviceid"}
                    placeholder="Enter your Deviceid"
                    value={formik?.values?.deviceid}
                    onChange={formik.handleChange}
                    style={{ width: "150px" }}
                  />
                  {formik.errors.deviceid && formik.touched.deviceid ? (
                    <p className="error">{formik.errors.deviceid}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                    type="text"
                    name={"devicename"}
                    placeholder="Enter your Device Name"
                    value={formik?.values?.devicename}
                    onChange={formik.handleChange}
                    style={{ width: "150px" }}
                  />
                  {formik.errors.devicename && formik.touched.devicename ? (
                    <p className="error">{formik.errors.devicename}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                    type="text"
                    name={"subnetid"}
                    placeholder="Enter your Subnetid"
                    value={formik?.values?.subnetid}
                    onChange={formik.handleChange}
                    style={{ width: "150px" }}
                  />
                  {formik.errors.subnetid && formik.touched.subnetid ? (
                    <p className="error">{formik.errors.subnetid}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                    type="text"
                    name={"channelid"}
                    placeholder="Enter your Channe Id"
                    value={formik?.values?.channelid}
                    onChange={formik.handleChange}
                    style={{ width: "150px" }}
                  />
                  {formik.errors.channelid && formik.touched.channelid ? (
                    <p className="error">{formik.errors.channelid}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Check
                    type="checkbox"
                    name={"Isautomatic"}
                    value={formik?.values?.Isautomatic}
                    onChange={formik.handleChange}
                  />
                </td>
                <td>
                  <Form.Control
                    type="time"
                    name={"startTime"}
                    value={formik?.values?.startTime}
                    step="900"
                    onChange={formik.handleChange}
                  />
                </td>
                <td>
                  <Form.Control
                    type="time"
                    name={"EndTime"}
                    value={formik?.values?.EndTime}
                    step="900"
                    onChange={formik.handleChange}
                  />
                </td>
                <td>
                  <Form.Select
                    type="select"
                    name={"DayofWeek"}
                    value={formik?.values?.DayofWeek}
                    onChange={formik.handleChange}
                    style={{ width: "150px" }}
                  >
                    <option>Select Day</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                  </Form.Select>
                  {formik.errors.DayofWeek && formik.touched.DayofWeek?(
                    <p className="error">{formik.errors.DayofWeek}</p>
                  ) : null}
                </td>
                <td>
                <div className="d-flex">
                    <AddButton type={"submit"} onClick={formik.handleSubmit}/>
                    <CancelButton onClick={cancelvalue} />
                </div>
                </td>
              </tr>
              {data.length > 0 &&
                data.map((item) => {
                  {/* console.log("qqqqqqqqqqqqqqq",item.Isautomatic) */}
                  return (
                    <tr key={item._id}>
                      <td>{item.deviceid}</td>
                      <td>{item.devicename}</td>
                      <td>{item.subnetid}</td>
                      <td>{item.channelid}</td>
                      <td>{item.Isautomatic}</td>
                      <td>{item.startTime}</td>
                      <td>{item.EndTime}</td>
                      <td>{item.DayofWeek}</td>
                      <td>
                        <Row sm={3} className="justify-content-center">
                          <Col>
                            <EditButton onClick={() => singalDetails(item._id)} />
                          </Col>
                          <Col>
                            <DeleteButton
                              onClick={() => handleDelete(item._id)}
                            />
                          </Col>
                        </Row>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
      </div>
    </>
  );
};

export default Irrigation;
