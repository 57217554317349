import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import Maintitle from "../../../../components/maintitle/Maintitle";
import { FaUserAlt, FaAt } from "react-icons/fa";
import {
  BsTelephoneForwardFill,
  BsFillEnvelopeFill,
  BsLockFill,
} from "react-icons/bs";
import { Tb123 } from "react-icons/tb";
import CustomButton from "../../../../components/button/CustomButton";
import { useFormik } from "formik";
import { schema } from "../../schema";
import { distadd } from "../../../../services/postServices";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorTost, SuccessTost } from "../../../../components/tost/Tostpop";
import { getsingleDatadist } from "../../../../services/getServices";
import { distupdate } from "../../../../services/putServices";

const Distributoradd = () => {
  const [title, setTitle] = useState(false);
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    distributercode: "",
  };

  const { id } = useParams();
  const [userData, setUserData] = useState(initialValues);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: userData,
    enableReinitialize: userData,
    validationSchema: schema,
    onSubmit: (params) => {
      console.log();
      if (id) {
        setTitle(false);
        distupdate(params, id)
          .then((res) => {
            navigate("/distributor");
            SuccessTost(res.data.message);
          })
          .catch((err) => {
            console.warn("error data here", err);
            ErrorTost("Something went wrong");
          });
      } else {
        distadd(params).then((res) => {
          if (res.status === 200) {
            // console.warn(res.data.message);
            SuccessTost(res.data.message);
            navigate("/distributor");
          } else {
            console.warn();
          }
        });
      }
    },
  });

  const singleData = () => {
    // const params = id
    getsingleDatadist(id)
      .then((res) => {
        setTitle(true);
        setUserData(res.data.data);
        // firstname:{(...userData.data.firstname)},
        // lastname:userData.data.lastname;
        // email:userData.data.email;
        // phone:userData.data.phone;
        // username:userData.data.username;
        // console.warn("error data here response", res.data.data);
        // distributercode:userData.data.distributer;
      })
      .catch((err) => {
        console.warn("error data here", err);
      });
  };
  useEffect(() => {
    if (id !== undefined) {
      singleData();
    }
  }, [id]);
  const goBack = () => {
    navigate("/distributor");
  };
  return (
    <>
      <Container className=" d-flex justify-content-center mt-5">
        <Form className="add">
          {title ? (
            <Maintitle heading={"Edit Distributor"} textAlign={"center"} />
          ) : (
            <Maintitle heading={"Add Distributor"} textAlign={"center"} />
          )}
          <div>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3 mt-3"
                  onSubmit={formik.handleSubmit}
                >
                  <Form.Label as="h6">First Name</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FaUserAlt color="#4a2125" size={20} /> 
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name={"firstname"}
                      placeholder="Enter your First Name"
                      value={formik?.values?.firstname}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                  {formik.errors.firstname && formik.touched.firstname ? (
                    <p className="error">{formik.errors.firstname}</p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label as="h6">Last Name</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FaUserAlt color="#4a2125" size={20}/>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name={"lastname"}
                      placeholder="Enter your Last Name"
                      value={formik?.values?.lastname}
                      onChange={formik.handleChange}
                      // isInvalid={formik.touched.password && formik.errors.password}
                    />
                  </InputGroup>
                  {formik.errors.lastname && formik.touched.lastname ? (
                    <p className="error">{formik.errors.lastname}</p>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <FormGroup className="mb-3">
              <Form.Label as="h6">Email Address</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <BsFillEnvelopeFill color="#4a2125" size={20} />
                </InputGroup.Text>
                <Form.Control
                  type="email"
                  name={"email"}
                  placeholder="Enter your Email Address"
                  value={formik?.values?.email}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.errors.email && formik.touched.email ? (
                <p className="error">{formik.errors.email}</p>
              ) : null}
            </FormGroup>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label as="h6">Number</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <BsTelephoneForwardFill color="#4a2125" size={20} />
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      name={"phone"}
                      placeholder="Enter your Number"
                      value={formik?.values?.phone}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                  {formik.errors.phone && formik.touched.phone ? (
                    <p className="error">{formik.errors.phone}</p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label as="h6">UserName</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FaAt color="#4a2125" size={20} />
                    </InputGroup.Text>
                    <Form.Control
                      type="username"
                      name={"username"}
                      placeholder="UserName"
                      value={formik?.values?.username}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                  {formik.errors.username && formik.touched.username ? (
                    <p className="error">{formik.errors.username}</p>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label as="h6">Password</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <BsLockFill color="#4a2125" size={20} />
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      name={"password"}
                      placeholder="Enter your Password"
                      value={formik?.values?.password}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                  {formik.errors.password && formik.touched.password ? (
                    <p className="error">{formik.errors.password}</p>
                  ) : null}
                </Form.Group>
              </Col>
              {/* <Col>
                <Form.Group className="mb-3">
                  <Form.Label as="h6">Distributer code</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <Tb123 color="#4a2125" size={25} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name={"distributercode"}
                      placeholder="Enter Distributor code"
                      value={formik?.values?.distributercode}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                  
                  {formik.errors.distributercode &&
                  formik.touched.distributercode ? (
                    <p className="error">{formik.errors.distributercode}</p>
                  ) : null}
                </Form.Group>
              </Col> */}
            </Row>
          </div>
          <div className="d-flex justify-content-between">
            <CustomButton buttonText={"Back"} onClick={() => goBack()} />
            <CustomButton
              buttonText={"ADD"}
              onClick={formik.handleSubmit}
              type={"submit"}
            />
          </div>
        </Form>
      </Container>
    </>
  );
};

export default Distributoradd;
