import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from "react-bootstrap";
import Maintitle from "../../../../components/maintitle/Maintitle";
import { FaUserAlt, FaAt } from "react-icons/fa";
import {
  BsTelephoneForwardFill,
  BsFillEnvelopeFill,
  BsLockFill,
} from "react-icons/bs";
import { Tb123 } from "react-icons/tb";
// import "./adduser.css";
import CustomButton from "../../../../components/button/CustomButton";
import { useFormik } from "formik";
import { schema } from "../../schema";
import { distuseradd } from "../../../../services/postServices";
import { useNavigate, useParams } from "react-router-dom";
import { getsingleDatadistuser } from "../../../../services/getServices";
import { distuserupdate } from "../../../../services/putServices";

const Distributoruseradd = () => {
  const [title, setTitle] = useState(false);
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    username: "",   
    password: "",
    distributercode: "",
  };

  const { id } = useParams();
  const [userData, setUserData] = useState(initialValues);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: userData,
    enableReinitialize: userData,
    validationSchema: schema,
    onSubmit: (params) => {
      // console.log(schema);
      if (id) {
        setTitle(false);
        distuserupdate(params, id)
          .then((res) => {
            setUserData(res.data.data);
            navigate("/distributoruser");
          })
          .catch((err) => {
            console.warn(("err data here", err));
          });
      } else {
        distuseradd(params).then((res) => {
          if (res.status === 200) {
            // console.warn(res.data.message);
            navigate("/distributoruser");
          } else {
            console.warn();
          }
        });
      }
    },
  });

  const singleData = () => {
    setTitle(true);
    getsingleDatadistuser(id)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.warn("error data here", err);
      });
  };
  useEffect(() => {
    if (id !== undefined) {
      singleData();
    }
  }, [id]);
  const goback = () => {
    navigate("/distributoruser");
  };
  return (
    <>
      <Container className=" d-flex justify-content-center mt-5">
        <Form className="add" method="POST" onSubmit={formik.handleSubmit}>
          {title ? (
            <Maintitle heading={"Edit Distributor User"} textAlign={"center"} />
          ) : (
            <Maintitle heading={"Add Distributor User"} textAlign={"center"} />
          )}

          <div>
            <Row>
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label as="h6">First Name</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FaUserAlt color="#4a2125" size={20} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name={"firstname"}
                      placeholder="Enter your First Name"
                      value={formik?.values?.firstname}
                      onChange={formik.handleChange}
                      {...formik.getFieldProps("firstname")}
                    />
                  </InputGroup>
                  {formik.errors.firstname && formik.touched.firstname ? (
                    <p className="error">{formik.errors.firstname}</p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label as="h6">Last Name</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FaUserAlt color="#4a2125" size={20}/>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name={"lastname"}
                      placeholder="Enter your Last Name"
                      value={formik?.values?.lastname}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                  {formik.errors.lastname && formik.touched.lastname ? (
                    <p className="error">{formik.errors.lastname}</p>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <FormGroup className="mb-3">
              <Form.Label as="h6">Email Address</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <BsFillEnvelopeFill color="#4a2125" size={20} />
                </InputGroup.Text>
                <Form.Control
                  type="email"
                  name={"email"}
                  placeholder="Enter Email Address"
                  value={formik?.values?.email}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.errors.email && formik.touched.email ? (
                <p className="error">{formik.errors.email}</p>
              ) : null}
            </FormGroup>
            <Row>
            <Col>
            <Form.Group className="mb-3">
              <Form.Label as="h6">Number</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <BsTelephoneForwardFill color="#4a2125" size={20} />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  name={"phone"}
                  placeholder="Enter your Number"
                  value={formik?.values?.phone}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.errors.phone && formik.touched.phone ? (
                <p className="error">{formik.errors.phone}</p>
              ) : null}
            </Form.Group>
            </Col>
            <Col>
            <Form.Group className="mb-3">
              <Form.Label as="h6">UserName</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FaAt color="#4a2125" size={20}/>
                </InputGroup.Text>
                <Form.Control
                  type="username"
                  name={"username"}
                  placeholder="User Name"
                  value={formik?.values?.username}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.errors.username && formik.touched.username ? (
                <p className="error">{formik.errors.username}</p>
              ) : null}
            </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col>
            <Form.Group className="mb-3">
              <Form.Label as="h6">Password</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <BsLockFill color="#4a2125" size={20} />
                </InputGroup.Text>
                <Form.Control
                  type="password"
                  name={"password"}
                  placeholder="Enter your Password"
                  value={formik?.values?.password}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.errors.password && formik.touched.password ? (
                <p className="error">{formik.errors.password}</p>
              ) : null}
            </Form.Group>
            </Col>
            <Col>
            <Form.Group className="mb-3">
              <Form.Label as="h6">Distributer code</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <Tb123  color="#4a2125" size={25}/>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name={"distributercode"}
                  placeholder="Enter Distributor code"
                  value={formik?.values?.distributercode}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.errors.distributercode &&
              formik.touched.distributercode ? (
                <p className="error">{formik.errors.distributercode}</p>
              ) : null}
            </Form.Group>
            </Col>
            </Row>
          </div>
          <div className="d-flex justify-content-between">
            <CustomButton buttonText={"Back"} onClick={() => goback()} />
            <CustomButton
              buttonText={"ADD"}
              onClick={formik.handleSubmit}
              type={"submit"}
            />
          </div>
        </Form>
      </Container>
    </>
  );
};

export default Distributoruseradd;
