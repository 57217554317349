import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import EditButton from "../../editbutton/EditButton";
import DeleteButton from "../../deletebutton/DeleteButton";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  getsingleDataZaudio,
  zaudioData,
  zaudiodataByZoneId,
} from "../../../services/getServices";
import { zaudioadd } from "../../../services/postServices";
import { Zaudioupdate } from "../../../services/putServices";
import AddButton from "../../Add/AddButton";
import CancelButton from "../../Cancel/CancelButton";

const Zaudio = ({ zoneId = "", mapData = [] }) => {
  const [data, setData] = useState(mapData);
  const [page, setPage] = useState(true);

  const initialValues = {
    devicename: "",
    deviceid: "",
    subnetid: "",
  };
  const [singleData, setsingleData] = useState(initialValues);
  const validationSchema = yup.object({
    devicename: yup.string().min(1).required("Please Enter your devicename"),
    deviceid: yup.number().min(1).required("Please Enter your deviceid"),
    subnetid: yup.number().min(1).required("Please Enter your Subnetid"),
  });

  const formik = useFormik({
    initialValues: singleData,
    enableReinitialize: singleData,
    validationSchema,
    onSubmit: (params) => {
      if (singleData?._id !== undefined) {
        Zaudioupdate(params, singleData._id)
          .then((res) => {
            if (res.status === 200) {
              setPage(true);
              setsingleData(initialValues);
            }
          })
          .catch((err) => {
            console.warn("errr", err);
          });
      } else {
        zaudioadd({ ...params, zoneid: zoneId })
          .then((res) => {
            if (res.status === 200) {
              console.warn(res.data.data);
              formik.resetForm();
              setPage(true);
            } else {
              console.warn();
            }
          })
          .catch((err) => {
            console.warn("dddddddd", err);
          });
      }
    },
  });
  const getZaudioDataByZoneId = () => {
    zaudiodataByZoneId(zoneId)
      .then((res) => {
        setData(res.data.data);
        setPage(false);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(() => {
    if ((zoneId, page)) {
      getZaudioDataByZoneId(zoneId);
    } else if (page) {
    }
  }, [page, zoneId]);

  const singalDetails = (params) => {
    getsingleDataZaudio(params)
      .then((res) => {
        setsingleData(res?.data?.data[0]);
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  const cancelvalue = () => {
    formik.resetForm();
  };
  return (
    <div>
      <Table striped hover responsive className="mt-5">
        <thead>
          <tr>
            <th>Device Name</th>
            <th>Device Id</th>
            <th>Subnet Id</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr onSubmit={formik.handleSubmit}> 
            <td>
              <Form.Control
                type="text"
                name={"devicename"}
                placeholder="Enter your Device Name"
                value={formik?.values?.devicename}
                onChange={formik.handleChange}
                style={{ width: "350px" }}
              />
              {formik.errors.devicename && formik.touched.devicename ? (
                <p className="error">{formik.errors.devicename}</p>
              ) : null}
            </td>
            <td>
              <Form.Control
                type="text"
                name={"deviceid"}
                placeholder="Enter your Device Id"
                value={formik?.values?.deviceid}
                onChange={formik.handleChange}
                style={{ width: "350px" }}
              />
              {formik.errors.deviceid && formik.touched.deviceid ? (
                <p className="error">{formik.errors.deviceid}</p>
              ) : null}
            </td>
            <td>
              <Form.Control
                type="number"
                name={"subnetid"}
                placeholder="Enter your subnetid"
                value={formik?.values?.subnetid}
                onChange={formik.handleChange}
                style={{ width: "350px" }}
              />
              {formik.errors.subnetid && formik.touched.subnetid ? (
                <p className="error">{formik.errors.subnetid}</p>
              ) : null}
            </td>
            <td>
              <div className="d-flex">
                <AddButton type={"submit"} onClick={formik.handleSubmit} />
                <CancelButton onClick={cancelvalue} />
              </div>
            </td>
          </tr>
          {data.length > 0 &&
            data.map((item) => {
              return (
                <tr key={item._id}>
                  <td>{item.devicename}</td>
                  <td>{item.deviceid}</td>
                  <td>{item.subnetid}</td>
                  <td>
                    <Row sm={3} className="justify-content-center">
                      <Col>
                        <EditButton onClick={() => singalDetails(item._id)} />
                      </Col>
                      <Col>
                        {/* <DeleteButton  onClick={() => handleDelete(item._id)} /> */}
                      </Col>
                    </Row>
                  </td>
                  <td></td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default Zaudio;
