import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import { Col, Row, Table,Form } from 'react-bootstrap';
import * as yup from "yup";
import EditButton from '../../editbutton/EditButton';
import { acadd } from "../../../services/postServices";
import { acDataByZoneId, acdata, getsingleDataAC } from "../../../services/getServices";
import DeleteButton from "../../deletebutton/DeleteButton";
import { acDelete } from "../../../services/deleteServices";
import { acupdate } from "../../../services/putServices";
import AddButton from "../../Add/AddButton";
import CancelButton from "../../Cancel/CancelButton";

const Acs = ({zoneId = '', mapData = [],}) => {
  const [data, setData] = useState(mapData);
  const [page, setPage] = useState(true);

  const initialValues = {
    devicename: "",
    ACtype: "",
    subnetid: "",
    deviceid: "",
    acsyncno: "",
  };
  const [singleData, setsingleData] = useState(initialValues);

  const validationSchema = yup.object({
    devicename: yup.string().min(3).required("Please Enter your devicename"),
    ACtype: yup.string().required("Please Select AC Type"),
    subnetid: yup.number().min(5).required("Please Enter your Subnetid"),
    deviceid: yup.number().min(5).required("Please Enter your deviceid"),
    acsyncno: yup.number().min(5).required("Please Enter your Acsyncno"),

  });
  const formik = useFormik({
    initialValues: singleData,
    enableReinitialize: singleData,
    validationSchema,
    onSubmit: (params) => {
      if(singleData?._id !==undefined){
        acupdate(params,singleData._id)
        .then((res) => {
          if(res.status === 200){
            setPage(true);
            setsingleData(initialValues)
          }
        })
        .catch((err) => {
          console.warn("errr",err);
        }) 
      }else{
       acadd({...params, zoneid: zoneId})
          .then((res) => {
            console.warn(res);
            if (res.status === 200) {
              console.warn(res.data.data);
              formik.resetForm();
              setPage(true);
            } else {
              console.warn();
            }
          })
          .catch((err) => {
            console.warn("dddddddd", err);
          });
        }
    },
  });
  const getacDataByZoneId = () => {
    acDataByZoneId(zoneId).then((res) => {
      if(res?.data) {
      setData(res.data.data);
      setPage(false);
    }
    })
    .catch((err) => {
      console.warn(err);
    });
  };
  useEffect(()=>{
    if(zoneId,page){
      getacDataByZoneId(zoneId);
    }else if(page){
      
    }
  },[page]);

  const singalDetails = (params) =>{
    getsingleDataAC(params)
    .then((res) => {
      setsingleData(res?.data?.data[0]);
    })
    .catch((err) => {
      console.warn(err);
    })
  };

  const cancelvalue = () => {
    formik.resetForm()
  }

  const handleDelete = (userid) =>{
    acDelete(userid);
    setPage(true);
  }
  return (
    <>
    <div data-testid="button">
          <Table striped hover responsive className="mt-5">
            <thead data-testid="user">
              <tr>
                <th>Device Name</th>
                <th>AC Type</th>
                <th>Subnet Id</th>
                <th>Device Id</th>
                <th>Acsyncno</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr onSubmit={formik.handleSubmit}>
                <td>
                  <Form.Control
                    type="text"
                    name={"devicename"}
                    placeholder="Enter your Device Name"
                    value={formik?.values?.devicename}
                    onChange={formik.handleChange}
                    style={{ width: "250px" }}
                  />
                  {formik.errors.devicename && formik.touched.devicename ? (
                    <p className="error">{formik.errors.devicename}</p>
                  ) : null}
                </td>
                <td >
                  <Form.Select
                    type="select"
                    name={"ACtype"}
                    value={formik?.values?.ACtype}
                    onChange={formik.handleChange}
                    style={{ width: "250px" }}
                  >
                    <option>Select AC</option>
                    <option value="AC">AC</option>
                    <option value="HVAC">HVAC</option>
                    <option value="AC With IR">AC With IR</option>
                  </Form.Select>
                  {formik.errors.ACtype && formik.touched.ACtype ? (
                    <p className="error">{formik.errors.ACtype}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                    type="text"
                    name={"subnetid"}
                    placeholder="Enter your Subnetid"
                    value={formik?.values?.subnetid}
                    onChange={formik.handleChange}
                    style={{width:"250px"}}
                  />
                  {formik.errors.subnetid && formik.touched.subnetid ? (
                    <p className="error">{formik.errors.subnetid}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                    type="text"
                    name={"deviceid"}
                    placeholder="Enter your Deviceid"
                    value={formik?.values?.deviceid}
                    onChange={formik.handleChange}
                    style={{width:"250px"}}
                  />
                  {formik.errors.deviceid && formik.touched.deviceid ? (
                    <p className="error">{formik.errors.deviceid}</p>
                  ) : null}
                </td>
                <td>
                  <Form.Control
                    type="text"
                    name={"acsyncno"}
                    placeholder="Enter your acsyncno"
                    value={formik?.values?.acsyncno}
                    onChange={formik.handleChange}
                    style={{width:"250px"}}
                  />
                  {formik.errors.acsyncno && formik.touched.acsyncno ? (
                    <p className="error">{formik.errors.acsyncno}</p>
                  ) : null}
                </td>
                <td>
                <div className="d-flex">
                    <AddButton type={"submit"} onClick={formik.handleSubmit} />
                    <CancelButton onClick={cancelvalue} />
                </div>
                </td>
              </tr>
              {data.length > 0 &&
                data.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>{item.devicename}</td>
                      <td>{item.ACtype}</td>
                      <td>{item.subnetid}</td>
                      <td>{item.deviceid}</td>
                      <td>{item.acsyncno}</td>
                      <td >
                        <Row sm={3} className="justify-content-center">
                          <Col >
                            <EditButton  onClick={() => singalDetails(item._id)}/>
                          </Col>
                          <Col>
                            <DeleteButton onClick={() => handleDelete(item._id)} />
                          </Col>
                        </Row>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
    </div>
          {/* <button data-testid={`EditButton`} onClick={(item) => singalDetails(item._id)}></button> */}
      </>
  )
}

export default Acs;
