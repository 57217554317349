import {
  AC_ADD_API,
  ADDUSER_API,
  AREA_ADD_API,
  CAMERA_ADD_API,
  CURATAIN_ADD_API,
  CUSTOMERSADD_API,
  DISTRIBUTERSADD_API,
  DISTRIBUTER_USERS_ADD_API,
  DMX_ADD_API,
  FAN_ADD_API,
  IRRIGATION_ADD_API,
  LIGHTS_ADD_API,
  LIGHTS_ADD_BY_ZONE_ID,
  LOGIN_API,
  MACDETAIL_ADD_API,
  MACROIR_ADD_API,
  OTHERCONTROLS_ADD_API,
  RGBW_ADD_API,
  SCENCE_ADD_API,
  SEQUENCE_ADD_API,
  ZAUDIO_ADD_API,
  ZONE_ADD_API,
} from "../constant/Api";
import { post, post2 } from "./api";

//Login
export const admin = async (params) => {
  return post(LOGIN_API, params);
};

//AdminUser Add
export const adminadd = async (params) => {
  return post(ADDUSER_API, params);
};

//Distributor Add
export const distadd = async (params) => {
  return post(DISTRIBUTERSADD_API, params);
};

//DistributorUser Add
export const distuseradd = async (params) => {
  return post(DISTRIBUTER_USERS_ADD_API, params);
};

//CustomerUser Add
export const custadd = async (params) => {
  return post(CUSTOMERSADD_API, params);
};

//Customer macid
export const custmacadd = async (params) => {
  return post(MACDETAIL_ADD_API, params);
};

//areas add
export const areaadd = async (params, id) => {
  return post2(AREA_ADD_API + id, params);
};

//areazone add
export const zoneadd = async (params,id) => {
  return post2(ZONE_ADD_API + id, params);
};

//lights
// export const  lightsadd = async (params) =>{
//   return post(LIGHTS_ADD_API,params)
// }
export const lightsadd = async (params) => {
  return post2(LIGHTS_ADD_API + params.zoneid, params);
};

//FAN
export const fanadd = async (params) => {
  return post(FAN_ADD_API + params.zoneid, params);
};

//AC
export const acadd = async (params) => {
  return post(AC_ADD_API + params.zoneid, params);
};

//Curtain
export const curtainadd = async (params) => {
  return post2(CURATAIN_ADD_API + params.zoneid, params);
};

//Camara
export const cameradd = async (params) => {
  return post(CAMERA_ADD_API + params.zoneid, params);
};

//scence
export const scenceadd = async (params) => {
  return post2(SCENCE_ADD_API + params.zoneid, params);
};

//Zaudio
export const zaudioadd = async (params) => {
  return post(ZAUDIO_ADD_API + params.zoneid, params);
};

//Sequence
export const sequenceadd = async (params) => {
  return post2(SEQUENCE_ADD_API + params.zoneid, params);
};

//Macroir
export const macroiradd = async (params) => {
  return post2(MACROIR_ADD_API + params.zoneid, params);
};

//Rgbw
export const rgbwadd = async (params) => {
  return post(RGBW_ADD_API + params.zoneid, params);
};

//DMX
export const dmxadd = async (params) => {
  return post(DMX_ADD_API + params.zoneid, params);
};

//Irrigation
export const irradd = async (params) => {
  return post(IRRIGATION_ADD_API + params.zoneid, params);
};

// Other Controls
export const controladd = async (params) => {
  return post2(OTHERCONTROLS_ADD_API + params.zoneid, params);
};