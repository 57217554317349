import React, { useEffect } from "react";
import { Card, Col, Form, Image, Row, Table } from "react-bootstrap";
import EditButton from "../editbutton/EditButton";
import DeleteButton from "../deletebutton/DeleteButton";
import { useFormik } from "formik";
import { useState } from "react";
import {
  areaalldata,
  customeruser,
  getsingleArea,
} from "../../services/getServices";
import { areaadd } from "../../services/postServices";
import * as yup from "yup";
import { areaDelete } from "../../services/deleteServices";
import AddButton from "../Add/AddButton";
import CancelButton from "../Cancel/CancelButton";
import { Dropdown } from "react-dropdown-now";
import "react-dropdown-now/style.css";
import { areaupdate } from "../../services/putServices";
import { ErrorTost } from "../tost/Tostpop";
import { useRoutes, useLocation } from "react-router-dom";
import { getItem } from "../../services/localStorage";

const Areas = ({ mapData = [] }) => {
  const initialValues = {
    title: "",
    subnetid: "",
    image: "",
  };

  const route = useLocation();
  const [data, setData] = useState(mapData);
  const [name, setName] = useState([]);
  const [singleData, setsingleData] = useState(initialValues);
  const [page, setPage] = useState(true);
  const [value, setValue] = useState("");
  const validationSchema = yup.object({
    title: yup
      .string()
      .required()
      .min(2)
      .max(120)
      .required("Please Enter your Title"),
    subnetid: yup.number().min(5).required("Please Enter your Subnetid"),
  });

  const CustomerId = getItem('_id')
  // console.log('route', CustomerId);
  const formik = useFormik({
    initialValues: singleData,
    enableReinitialize: singleData, 

    
    validationSchema,
    onSubmit: (params) => {
      if(singleData?._id !==undefined){
        areaupdate(params,singleData._id)
        .then((res) => {
          if(res.status === 200){
            setPage(true)
            setsingleData(initialValues)
          }
        })
        .catch((err) =>{
          console.log(err)
          ErrorTost(err.data.message);
        })
      }else{
      areaadd({ ...params,customerid:CustomerId, image: params.image }, CustomerId)
        .then((res) => {
          if (res.status === 200) {
            setPage(true);
            formik.resetForm();
          } else {
            console.warn();
          }
        })
        .catch((err) => {
          console.warn("dddddddd", err);
          ErrorTost(err.data.message);
        });
      }
    },
  });

  const getareadata = () => {
    areaalldata()
      .then((res) => {
        setData(res.data.data);
        setPage(false);
      })
      .catch((err) => {
        console.warn(err);
        ErrorTost(err.data.message);
      });
  };
  useEffect(() => {
    if (page) {
      getareadata();
    }
  }, [page]);

  const getCustomerUser = () => {
    customeruser()
      .then((res) => {
        let array = [];
        if (res?.data?.data.length > 0) {
          res?.data?.data.forEach((element) => {
            let item = {
              label: element.firstname,
              value: element._id, 
            };
            array.push(item);
          });
        }
        setName(array);
      })
      .catch((err) => {
        console.warn(err);
        ErrorTost(err.data.message);
      });
  };
  useEffect(() => {
    getCustomerUser();
  }, []);

  const singalDetails = (params) => {
    getsingleArea(params)
      .then((res) => {
        setsingleData(res?.data?.data[0]);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const cancelvalue = () => {
    formik.resetForm();
  };

  const handleDelete = (userid) => {
    areaDelete(userid);
    // setPage(true);
    // window.location.reload(true);
  };

  // const handleSelect = (e) => {
  //   setValue(e);
  // };

  return (
    <>
      <Card className="mt-5"> 
        <Table striped hover responsive className="mt-5">
          <thead>
            <tr>
              <th>Zone ID</th>
              <th></th>
              <th>Area</th>
              <th>Subnetid</th>
              <th>Image</th>
              <th>Arearole</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* <Form  > */}
            <tr onSubmit={formik.handleSubmit}>
              <td></td>
              <td>
                {/* <Dropdown
                  options={name}
                  value={value}
                  onChange={(value) => {
                    handleSelect(value);
                  }}
                /> */}
              </td>
              <td>
                <Form.Control
                  type="text"
                  name={"title"}
                  placeholder="Enter your title"
                  value={formik?.values?.title}
                  onChange={formik.handleChange}
                  style={{ width: "350px" }}
                />
                {formik.errors.title && formik.touched.title ? (
                  <p className="error">{formik.errors.title}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="text"
                  name={"subnetid"}
                  placeholder="Enter your Subnetid"
                  value={formik?.values?.subnetid}
                  onChange={formik.handleChange}
                  style={{ width: "350px" }}
                />
                {formik.errors.subnetid && formik.touched.subnetid ? (
                  <p className="error">{formik.errors.subnetid}</p>
                ) : null}
              </td>
              <td>
                {" "}
                <Form.Control
                  type="file"
                  name={"image"}
                  placeholder="Select your image"
                  value={formik?.file?.image}
                  style={{ width: "400px" }}
                  onChange={(e) =>
                    formik.setFieldValue("image", e.currentTarget.files[0])
                  }
                />
                {/* {formik.errors.image && formik.touched.image ? (
                  <p className="error">{formik.errors.image}</p>
                ) : null} */}
              </td>
              <td></td>
              <td>
                <div className="d-flex">
                  <AddButton type={"submit"} onClick={formik.handleSubmit} />
                  <CancelButton onClick={cancelvalue}/>
                </div>
              </td>
            </tr>
            {/* </Form> */}
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <tr key={item?._id}>
                    {/* <td>{item.zoneid}</td> */}
                    <td>{item?.Areazoneid}</td>
                    <td>{item?.firstname}</td>
                    <td>{item?.title}</td>
                    <td>{item?.subnetid}</td>
                    <td>
                      <img
                        src={item?.image}
                        alt="default"
                        width="100px"
                        height="50px"
                      />
                    </td>
                    <td>{item.arearole}</td>
                    <td>
                      <Row md={3} className="justify-content-center">
                        <Col>
                          <EditButton onClick={() => singalDetails(item._id)} />
                        </Col>
                        <Col>
                          <DeleteButton
                            onClick={() => handleDelete(item._id)}
                          />
                        </Col>
                      </Row>
                    </td>
                    <td></td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default Areas;
