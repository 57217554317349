// export const BASE_URL = "http://192.168.113.15:3000";
// export const BASE_URL = "http://192.168.113.10:3000";
// export const BASE_URL = "http://192.168.113.11:3001";
export const BASE_URL = "http://103.1.100.53:3000";  
// getDistributer

//Login
export const LOGIN_API = BASE_URL + "/adminLogin";

//Admmin User
export const ALLDETAILS_API = BASE_URL + "/adminData";
export const ADDUSER_API = BASE_URL + "/registerAdmin"; 
export const SINGLEDATA_USER_API = BASE_URL + "/adminGet/";
export const USER_UPDATE_API = BASE_URL + "/adminUpdate/";
export const ADMIN_USER_DELETE_API = BASE_URL + "/adminDelete/";

//Distributers
export const DISTRIBUTER_API = BASE_URL + "/distributersData";
export const DISTRIBUTER_SINGLE_API = BASE_URL + "/getDistributer";
export const DISTRIBUTERSADD_API = BASE_URL + "/registerDistributer";   
export const SINGLEDATA_DISTRIBUTER_API = BASE_URL + "/distributerData/"; 
export const UPDATE_DISTRIBUTER_API = BASE_URL + "/distributerUpdate/";
export const DISTRIBUTER_DELETE_API = BASE_URL + "/distributerDelete/";

//Distributers login show his customer data
export const DISTRIBUTER_CUSTOMER_API = BASE_URL +"/customerDetail";

//Distributeruser login show his customer data
export const DISTRIBUTER_USER_CUSTOMER_API = BASE_URL +"/customersData";

//Distributers User
export const DISTRIBUTER_USER_ALL_DETAILS_API =
  BASE_URL + "/distributerUsersData";
export const DISTRIBUTER_USER_SINGLE_DETAILS_API =
  BASE_URL + "/getDistributerUser";
export const DISTRIBUTER_USER_ROLE_DETAILS_API =
  BASE_URL + "/userDetails";
export const DISTRIBUTER_USERS_ADD_API = BASE_URL + "/registerDistributerUser";
export const SINGLEDATA_DISTRIBUTER_USER_API =
  BASE_URL + "/distributerUserData/";
export const UPDATE_DISTRIBUTER_USER_API = BASE_URL + "/distributerUserUpdate/";
export const DISTRIBUTERUSER_DELETE_API = BASE_URL + "/distributerUserDelete/";

//Customer User
export const CUSTOMERS_ALL_DETAILS_API = BASE_URL + "/customerData";
export const CUSTOMERSADD_API = BASE_URL + "/registerCustomer";
export const SINGLEDATA_CUSTOMER_API = BASE_URL + "/customerData/";
export const UPDATE_CUSTOMERS_API = BASE_URL + "/customerUpdate/";
export const CUSTOMERS_USER_DELETE_API = BASE_URL + "/customerDelete/";

// Customer mcaid
export const MCAID_ALL_DETAILS_API = BASE_URL + "/macdetail";
export const MACDETAIL_ADD_API = BASE_URL + "/addmacadress";
export const SINGAL_MACDETAIL_API = BASE_URL + "/macdetailbyid/";
export const UPDATE_MCAID_API = BASE_URL + "/updatemac/";
export const MACDETAIL_DELETE_API = BASE_URL + "/deletemac/";

//Area
export const AREA_ALL_DETAILS_API = BASE_URL + "/getareaadmin";
export const AREA_ADD_API = BASE_URL + "/addareaadmin/";
export const SINGL_AREA_API = BASE_URL + "/getareabyidadmin/";
// export const UPDATE_AREA_API = BASE_URL + "/updatearea/";
export const UPDATE_AREA_API = BASE_URL + "/updateareaadmin/";
export const DELETE_AREA_API = BASE_URL + "/deleteareaadmin/";

//AreaZone

// export const ZONE_ALL_DETAILS_API = BASE_URL +"/zoneDetail";
export const ZONE_ADD_API = BASE_URL + "/addzone/";
export const ZONE_ALL_DETAILS_API = BASE_URL + "/getareazone";

//Device

//Light
// export const LIGHTS_ALL_DETAILS_API = BASE_URL +"/getlight";
export const LIGHTS_ADD_API = BASE_URL + "/addlight/";
// export const LIGHTS_ADD_BY_ZONE_ID = BASE_URL + "/addlight/"
export const LIGHTS_BY_ZONE_ID = BASE_URL + "/getlightbyzone/";
export const SINGAL_LIGHTS_API = BASE_URL + "/getlightbyid/";
export const UPDATE_LIGHTS_API = BASE_URL + "/updatelight/";
export const LIGHTS_DELETE_API = BASE_URL + "/deletelight/";

//Fan
export const FAN_BY_ZONE_ID = BASE_URL + "/getfanbyzone/";
export const FAN_ADD_API = BASE_URL + "/addfan/";
export const SINGAL_FAN_API = BASE_URL + "/getfanbyid/";
export const UPDATE_FAN_API = BASE_URL + "/updatefan/";
export const FAN_DELETE_API = BASE_URL + "/deletefan/";

//AC
export const AC_BY_ZONE_ID = BASE_URL + "/getACbyzone/";
export const AC_ADD_API = BASE_URL + "/addAC/";
export const SINGAL_AC_API = BASE_URL + "/getACbyid/";
export const UPDATE_AC_API = BASE_URL + "/updateAC/";
export const AC_DELETE_API = BASE_URL + "/deleteAC/";

//Curtain
export const CURATAIN_BY_ZONE_ID = BASE_URL + "/getcuratinbyzone/";
export const CURATAIN_ADD_API = BASE_URL + "/addcurtain/";
export const SINGAL_CURTAIN_API = BASE_URL + "/getcuratinbyid/";
export const UPDATE_CURTAINS_API = BASE_URL + "/updatecuratin/";
export const CURATAIN_DELETE_API = BASE_URL + "/deletecuratin/";

//Camra
export const CAMRA_BY_ZONE_ID = BASE_URL + "/Camerabyzone/";
export const CAMERA_ADD_API = BASE_URL + "/addCamera/";
export const SINGAL_CAMERA_API = BASE_URL + "/Camerabyid/";
export const UPDATE_CAMERAS_API = BASE_URL + "/updateCamera/";
export const CAMERA_DELETE_API = BASE_URL + "/deleteCamera/";

//Scence
export const SCENCE_ALL_DETAILS_API = BASE_URL + "/getScence";
export const SCENCE_BY_ZONE_ID = BASE_URL + "/Scencebyzone/";
export const SCENCE_ADD_API = BASE_URL + "/addScence/";
export const SINGLE_SCENE_API = BASE_URL + "/Scencebyid/";
export const UPDATE_SCENCES_API = BASE_URL + "/updateScence/";
export const SCENCE_DELETE_API = BASE_URL + "/deleteScence/";

//Zaudio
export const ZAUDIO_BY_ZONE_ID = BASE_URL + "/getzaudiobyzone/";
export const ZAUDIO_ADD_API = BASE_URL + "/addzaudio/";
export const SINGEL_ZAUDIO_API = BASE_URL + "/getzaudio/";
export const UPDATED_ZAUDIO_API = BASE_URL + "/updatezaudiobyid/";

//Sequence
export const SEQUENCE_ALL_DETAILS_API = BASE_URL + "/getSequence";
export const SEQUENCE_BY_ZONE_ID = BASE_URL + "/Sequencebyzone/";
export const SEQUENCE_ADD_API = BASE_URL + "/addSequence/";
export const SINGLE_SEQUENCE_API = BASE_URL + "/Sequencebyid/";
export const UPDATE_SEQUENCES_API = BASE_URL + "/updateSequence/";
export const SEQUENCE_DELETE_API = BASE_URL + "/deleteSequence/";

//Macroir
export const MACROIR_BY_ZONE_ID = BASE_URL + "/macroirbyzone/";
export const MACROIR_ADD_API = BASE_URL + "/addmacroir/";
export const SINGLE_MACROIR_API = BASE_URL + "/macroirbyid/";
export const UPDATE_MACROS_API = BASE_URL + "/updatemacroir/";
export const MACROIR_DELETE_API = BASE_URL + "/deletemacroir/";

//RGBW
export const RGBW_BY_ZONE_ID = BASE_URL + "/rgbwbyzone/";
export const RGBW_ADD_API = BASE_URL + "/addrgbw/";
export const SINGLE_RGBW_API = BASE_URL + "/rgbwbyid/";
export const UPDATE_RGBWS_API = BASE_URL + "/updatergbw/";
export const RGBW_DELETE_API = BASE_URL + "/deletergbw/";

//DMX
export const DMX_BY_ZONE_ID = BASE_URL + "/getdmxbyzone/";
export const DMX_ADD_API = BASE_URL + "/adddmx/";
export const DMX_DELETE_API = BASE_URL + "/deletechannel/";

//Irrigation
export const IRRIGATION_BY_ZONE_ID = BASE_URL + "/irrigationbyzone/";
export const IRRIGATION_ADD_API = BASE_URL + "/addirrigation/";
export const SINGLE_IRRIGATION_API = BASE_URL + "/irrigationbyid/";
export const UPDATE_IRRIGATIONS_API = BASE_URL + "/updateirrigation/";
export const IRRIGATION_DELETE_API = BASE_URL + "/deleteirrigation/";

//Other Controls
export const OTHERCONTROLS_BY_ZONE_ID = BASE_URL + "/getsettingbyzone/";
export const OTHERCONTROLS_ADD_API = BASE_URL + "/addsetting/";
export const SINGLE_OTHERCONTROLS_API = BASE_URL + "/getsettingbyid/";
export const UPDATE_OTHERCONTROLS_API = BASE_URL + "/updatesetting/";
export const OTHERCONTROLS_DELETE_API = BASE_URL + "/deletesetting/";
