import {
  AC_BY_ZONE_ID,
  // ADMIN_EDITUSER_API,
  ALLDETAILS_API,
  AREA_ALL_DETAILS_API,
  CAMRA_BY_ZONE_ID,
  CURATAIN_BY_ZONE_ID,
  CUSTOMERS_ALL_DETAILS_API,
  DISTRIBUTER_API,  
  DISTRIBUTER_CUSTOMER_API,  
  DISTRIBUTER_SINGLE_API,
  DISTRIBUTER_USER_ALL_DETAILS_API,
  DISTRIBUTER_USER_CUSTOMER_API,
  DISTRIBUTER_USER_ROLE_DETAILS_API,
  DISTRIBUTER_USER_SINGLE_DETAILS_API,
  DMX_BY_ZONE_ID,
  FAN_BY_ZONE_ID,
  IRRIGATION_BY_ZONE_ID,
  LIGHTS_ALL_DETAILS_API,
  LIGHTS_BY_ZONE_ID,
  MACROIR_ALL_DETAILS_API,
  MACROIR_BY_ZONE_ID,
  MCAID_ALL_DETAILS_API,
  OTHERCONTROLS_BY_ZONE_ID,
  RGBW_BY_ZONE_ID,
  SCENCE_ALL_DETAILS_API,
  SCENCE_BY_ZONE_ID,
  SEQUENCE_ALL_DETAILS_API,
  SEQUENCE_BY_ZONE_ID,
  SINGAL_AC_API,
  SINGAL_CAMERA_API,
  SINGAL_CURTAIN_API,
  SINGAL_FAN_API,
  SINGAL_LIGHTS_API,
  SINGAL_MACDETAIL_API,
  SINGEL_ZAUDIO_API,
  SINGLEDATA_CUSTOMER_API,
  SINGLEDATA_DISTRIBUTER_API,
  SINGLEDATA_DISTRIBUTER_USER_API,
  SINGLEDATA_USER_API,
  SINGLE_IRRIGATION_API,
  SINGLE_MACROIR_API,
  SINGLE_OTHERCONTROLS_API,
  SINGLE_RGBW_API,
  SINGLE_SCENE_API,
  SINGLE_SEQUENCE_API,
  SINGL_AREA_API,
  ZAUDIO_BY_ZONE_ID,
  ZONE_ALL_DETAILS_API,
} from "../constant/Api";
import { get } from "./api";

//Admin User
  export const alldetails = async () => {
    return get(ALLDETAILS_API);
  };
// export const admineditsuser = async (_id) => {
//   return get(ADMIN_EDITUSER_API +_id);
// };
export const getsingleData = async (params) => {
  return get(SINGLEDATA_USER_API + params);
};

//Distributer
export const distributers = async () => {
  return get(DISTRIBUTER_API);
};
export const distributersingle = async () => {
  return get(DISTRIBUTER_SINGLE_API);
};
export const getsingleDatadist = async (params) => {
  return get(SINGLEDATA_DISTRIBUTER_API + params);
};

//Distributeruser
export const distributersuser = async () => {
  return get(DISTRIBUTER_USER_ALL_DETAILS_API);
};
export const distributersusersingle = async () => {
  return get(DISTRIBUTER_USER_SINGLE_DETAILS_API);
};
export const distributersuserrole = async () => {
  return get(DISTRIBUTER_USER_ROLE_DETAILS_API);
};
export const getsingleDatadistuser = async (params) => {
  return get(SINGLEDATA_DISTRIBUTER_USER_API + params);
};

 //Distributers login show his customer data
 export const distcustomer = async () => {
  return get(DISTRIBUTER_CUSTOMER_API);
};

 //Distributeruser login show his customer data
 export const distusercust = async () => {
  return get(DISTRIBUTER_USER_CUSTOMER_API);
};
//Customeruser
export const customeruser = async () => {
  return get(CUSTOMERS_ALL_DETAILS_API);
};
export const getsingleDatacustomer = async (params) => {
  return get(SINGLEDATA_CUSTOMER_API + params);
};


export const custmcaid = async () => {
  return get(MCAID_ALL_DETAILS_API);
};
export const getsingalcustmcaid = async (params) => {
  return get(SINGAL_MACDETAIL_API + params);
};

//Area
export const areaalldata = async () => {
  return get(AREA_ALL_DETAILS_API);
};
export const getsingleArea = async (params) => {
  return get(SINGL_AREA_API + params);
};
//AreaZone
export const zonealldata = async () => {
  return get(ZONE_ALL_DETAILS_API);
};

//Light
// export const lightalldata = async() =>{
//   return get(LIGHTS_ALL_DETAILS_API)
// }
export const lightDataByZoneId = async (params) => {
  return get(LIGHTS_BY_ZONE_ID + params);
};

export const getsingleDatalight = async (params) => {
  return get(SINGAL_LIGHTS_API + params);
};

//fan
export const fanDataByZoneId = async (params) => {
  return get(FAN_BY_ZONE_ID + params);
};
export const getsingleDataFAN = async (params) => {
  return get(SINGAL_FAN_API + params);
};

// AC
export const acDataByZoneId = async (params) => {
  return get(AC_BY_ZONE_ID + params);
};

export const getsingleDataAC = async (params) => {
  return get(SINGAL_AC_API + params);
};

//Curatain
export const curatinDataByZoneId = async (params) => {
  return get(CURATAIN_BY_ZONE_ID + params);
};

export const getsingleDataCurt = async (params) => {
  return get(SINGAL_CURTAIN_API + params);
};

//Camra
export const camraDataByZoneId = async (params) => {
  return get(CAMRA_BY_ZONE_ID + params);
};
export const getsingleDataCamra = async (params) => {
  return get(SINGAL_CAMERA_API + params);
};

//Scence
// export const scenceData = () => {
//   return get(SCENCE_ALL_DETAILS_API )
// }
export const scenceDataByZoneId = async (params) => {
  return get(SCENCE_BY_ZONE_ID + params);
};
export const getsingleDataSence = (params) => {
  return get(SINGLE_SCENE_API + params);
};

//Zaudio
export const zaudiodataByZoneId = async (params) => {
  return get(ZAUDIO_BY_ZONE_ID + params);
};
export const getsingleDataZaudio = async (params) => {
  return get(SINGEL_ZAUDIO_API + params);
};

//Sequence
// export const sequenceData = () => {
//   return get(SEQUENCE_ALL_DETAILS_API);
// }
export const sequedataByZoneId = async (params) => {
  return get(SEQUENCE_BY_ZONE_ID + params);
};

export const getsingleDataSeques = async (params) => {
  return get(SINGLE_SEQUENCE_API + params);
};

//MACROIR
export const macrodataByZoneId = async (params) => {
  return get(MACROIR_BY_ZONE_ID + params);
};
export const getsingleDataMacro = async (params) => {
  return get(SINGLE_MACROIR_API + params);
};

//RGBW
export const rgbwdataByZoneId = async (params) => {
  return get(RGBW_BY_ZONE_ID + params);
};
export const getsingleDataRgbw = async (params) => {
  return get(SINGLE_RGBW_API + params);
};

//DMX
export const dmxdataByZoneId = async (params) => {
  return get(DMX_BY_ZONE_ID + params);
};

//Irrigation
export const irrigationdataByZoneId = async (params) => {
  return get(IRRIGATION_BY_ZONE_ID + params);
};
export const getsingleDataIrri = async (params) => {
  return get(SINGLE_IRRIGATION_API + params);
};

//Other controls
export const OtherdataByZoneId = async (params) => {
  return get(OTHERCONTROLS_BY_ZONE_ID + params);
};
export const getsingalOtherdata = async (params) => {
  return get(SINGLE_OTHERCONTROLS_API + params);
};