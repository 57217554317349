import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";

const EditButton = ({ onClick }) => {
  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Edit</strong>
    </Tooltip>
  );
  return (
    // <button type="button" aria-label="Edit" style={{backgroundColor:"white", border:"none"}}>
      <OverlayTrigger placement="bottom" overlay={tooltip} delayShow={50}>
        <BiSolidEdit aria-label="Edit" size={25} onClick={onClick} title="Edit" aria-roledescription="Edit" />
      </OverlayTrigger>
    // </button>
  );
};

export default EditButton;