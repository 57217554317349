import React, { useState, useEffect } from "react";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import DeleteButton from "../deletebutton/DeleteButton";
import { custmcaid, getsingalcustmcaid } from "../../services/getServices";
import { custmacDelete } from "../../services/deleteServices";
import EditButton from "../editbutton/EditButton";
import { useDispatch, useSelector } from "react-redux";
import { getisReloadFlag, macreload, zone } from "../../redux/authSlice";
import { useFormik } from "formik";
import { custmacadd } from "../../services/postServices";
import * as yup from "yup";
import { macupdate } from "../../services/putServices";
import AddButton from "../Add/AddButton";
import CancelButton from "../Cancel/CancelButton";

const Mactable = ({mapData=[]}) => {
  const initialValues = {
    macid: "",
    ipaddress: "",
  };

  const isZoneFlage = useSelector((state) => state.authentication.isZoneFlage);
  const [data, setData] = useState(mapData);
  const [singleData, setsingleData] = useState(initialValues);
  const [page, setPage] = useState(true);
  // console.log("redux value ", singleData?._id);
  const dispatch = useDispatch();

  //validation

  const validationSchema = yup.object({
    macid: yup.string().max(20).required("Please Enter your Mac id"),
    ipaddress: yup.string().max(15).required("Please Enter your Ip Address"),
  });

  const formik = useFormik({
    initialValues: singleData,
    enableReinitialize: singleData,
    validationSchema,

    onSubmit: (params) => {
      // let values = {
      //   macid: formik.values.macid,
      //   ipaddress: formik.values.ipaddress,
      // };
      // console.log('pass payload for update',values);
      if (singleData?._id !== undefined) {
        macupdate(params, singleData._id)
          .then((res) => {
            if (res.status === 200) {
              // console.log("pass payload for update", values);
              setPage(true);
              setsingleData(initialValues)
              // dispatch(zone(""));
            }
          })
          .catch((err) => {
            console.warn("errr", err);
          });
      } else {
        custmacadd(params).then((res) => {
          if (res.status === 200) {
            setPage(true);
            formik.resetForm();
          } else {
            console.warn();
          }
        });
      }
    },
  });

  const getCustomermcaid = () => {
    custmcaid()
      .then((res) => {
        setData(res.data.data);
        dispatch(macreload(false));
        setPage(false);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  useEffect(() => {
    if (page) {
      getCustomermcaid();
    }
  }, [page]);

  const singalDetailsMac = (params) => {
    getsingalcustmcaid(params)
      .then((res) => {
        console.log("sdfghjk",res.data.data);
        setsingleData(res?.data?.data[0]);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const cancelvalue = () => {
    formik.resetForm()
  }

  const handleDelete = (userid, e) => {
    custmacDelete(userid);
    // window.location.reload(true);
    setPage(true);
  };
  return (
    <>
      <Card className="mt-5">
        <Table striped hover responsive className="mt-5">
          <thead>
            <tr>
              <th>Customer ID</th>
              <th>Mac ID</th>
              <th>Ip Address</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* <Form  > */}
            <tr onSubmit={formik.handleSubmit}>
              <td></td>
              <td>
                <Form.Control
                  type="text"
                  name={"macid"}
                  placeholder="Enter your Macid"
                  value={formik?.values?.macid}
                  onChange={formik.handleChange}
                />
                {formik.errors.macid && formik.touched.macid ? (
                  <p className="error">{formik.errors.macid}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="text"
                  name={"ipaddress"}
                  placeholder="Enter your Ip Address"
                  value={formik?.values?.ipaddress}
                  onChange={formik.handleChange}
                />
                {formik.errors.ipaddress && formik.touched.ipaddress ? (
                  <p className="error">{formik.errors.ipaddress}</p>
                ) : null}
              </td>
              <td>
              <div className="d-flex">
                    <AddButton type={"submit"} onClick={formik.handleSubmit} />
                    <CancelButton onClick={cancelvalue} />
                </div>
              </td>
            </tr>
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item.customerid}</td>
                    <td>{item.macid}</td>
                    <td>{item.ipaddress}</td>
                    <td>
                      <Row sm={3} className="justify-content-center">
                        <Col>
                          <EditButton
                            onClick={() => singalDetailsMac(item._id)}
                          />
                        </Col>
                        <Col>
                          <DeleteButton
                            onClick={() => handleDelete(item._id)}
                          />
                        </Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default Mactable;
