import * as yup from "yup";

export const schema = yup.object().shape({
  firstname: yup.string().min(2).max(25).required("Please Enter Your FirstName"),
  lastname: yup.string().min(2).max(25).required("Please Enter Your LastName"),
  email: yup
    .string()
    .email("Invalid email")
    .required("Please Enter Your Email"),
  phone: yup.number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(10, "too short")
  // .max(10, "too long")
    .required("A phone number is Required"),
    username: yup.string()
    .min(1, "Mininum 1 characters")
    .max(15, "Maximum 15 characters")
    // .test("username", "This username has already been taken")  
    .required("You must Enter a username"),
  password: yup.string().min(8).required("Please Enter Your Password"),
  // distributercode: yup.string()
  // .required("Enter Your Distributercode ")
  // // .matches(/^[0-9]+$/, "Must be only digits")
  // .min(5, 'Must be Exactly 5 digits')
  // .max(10, 'Must be Exactly 10 digits')
});
