import React from 'react'
import "./custombutton.css"
import { Button } from 'react-bootstrap'

const CustomButton = ({type,onClick,buttonText,color}) => {
  return (
    <>
           <Button
            type={type}
            className="button"
            onClick={onClick}>
            {buttonText}
            </Button>
    </>
  )
}

export default CustomButton