import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export const SuccessTost = (message) =>{
    toast.success(message, {
        position: toast.POSITION.TOP_LEFT
      });
    }
export const ErrorTost = (message) =>{
    toast.error(message, {
        position: toast.POSITION.TOP_LEFT
      });
    }
