import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ImCancelCircle } from "react-icons/im";
import { BsXCircleFill } from "react-icons/bs";

const CancelButton = ({ onClick, type }) => {
  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Cancel</strong> 
    </Tooltip>
  );  

  return (
    <div>
      <OverlayTrigger placement="bottom" overlay={tooltip} delayShow={50}>
        <BsXCircleFill size={23} onClick={onClick} type={type} title="Cancel" />
      </OverlayTrigger>
    </div>
  );
};

export default CancelButton;