import React, { useState, useEffect } from "react";
import Maintitle from "../../../components/maintitle/Maintitle";
import { Card, Col, Row, Table } from "react-bootstrap";
import "./adminuser.css";
import CustomButton from "../../../components/button/CustomButton";
import { useNavigate } from "react-router-dom";
import PaginationNew from "../../../components/pagination/PaginationNew";
import Loader from "../../../components/loader/Loader";
import { alldetails } from "../../../services/getServices";
import EditButton from "../../../components/editbutton/EditButton";
import DeleteButton from "../../../components/deletebutton/DeleteButton";
import { deleteUser } from "../../../services/deleteServices";
import { ErrorTost } from "../../../components/tost/Tostpop";

const AdminUser = ({ mapData = [] }) => {
  const [admindata, setAdminData] = useState(mapData);
  const [isloading, setLoading] = useState(false);
  const [pagecount, setPagecount] = useState(0);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(true);
  const navigate = useNavigate();

  const getAdminUserData = () => {
    setLoading(true);
    alldetails()
      .then((res) => {
        // console.log("resss",res.data.data);
        setAdminData(res.data.data);
        setPagecount(res.data.total);
        setLoading(false);
      })
      .catch((err) => {
        console.warn(err);
        ErrorTost(err.data.message);
      });
  };
  useEffect(() => {
    getAdminUserData();
  }, [page]);

  const handleDelete = (userid, e) => {
    deleteUser(userid);
    setPages(true);
    // window.location.reload(true);
  };

  const handleAdmin = (_id) => {
    console.log("Customerid", _id);
    navigate("/adduseredit/" + `${_id}`);
  };

  return (
    <Row>
      <Loader isLoading={isloading} />

      <Card className="pt-5">
        <div className="d-flex justify-content-between px-5">
          <Maintitle heading={"Admin"} textAlign={"center"} />
          <CustomButton
            buttonText={"Add"}
            onClick={() => navigate("/Adduser")}
          />
        </div>
        <Table hover responsive className="mt-3">
          <thead>
            <tr>
              <th>Admin ID</th>
              <th>F_Name</th>
              <th>L_Name</th>
              <th>Email</th>
              <th>Phone No.</th>
              <th>User Name</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {admindata.length > 0 &&
              admindata.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item?.userid + "."}</td>
                    <td>{item?.firstname}</td>
                    <td>{item?.lastname}</td>
                    <td>{item?.email}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.username}</td>
                    <td>
                      <Row sm={12} className="justify-content-center">
                        <Col sm={3}>
                          <EditButton onClick={() => handleAdmin(item._id)} />
                        </Col>
                        <Col sm={3}>
                          <DeleteButton
                            onClick={() => handleDelete(item._id)}
                          />
                        </Col>
                      </Row>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
          <div>
          <PaginationNew pageCount={3} setPage={setPage} page={page} />
        </div>
      </Card>
    </Row>
  );
};

export default AdminUser;
