import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import CustomButton from "../button/CustomButton";
import * as yup from "yup";
import { useFormik } from "formik";
import { zoneadd } from "../../services/postServices";
import { ErrorTost } from "../tost/Tostpop";
import { areaalldata } from "../../services/getServices";
import AddButton from "../Add/AddButton";

const Model = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(true);
  const [value, setValue] = useState("");
  const [areaZoneId, setAreaZoneId] = useState(0);

  const initialValues = {
    title: "",
    subnetid: "",
    image: "",
  };

  const validationSchema = yup.object({
    title: yup
      .string()
      .required()
      .min(2)
      .max(120)
      .required("Please Enter your Title"),
    subnetid: yup.number().min(5).required("Please Enter your Subnetid"),
  });

  useEffect(() => {
    const areaItem = data.find((item) => item.title === value);
    const AreaZoneID = areaItem ? areaItem._id : '';
    setAreaZoneId(AreaZoneID);
    // console.log('useEffect ', AreaZoneID);
  }, [value])

  const formik = useFormik({
    initialValues,
    // enableReinitialize:data,
    validationSchema,     
    onSubmit: (params) => {
      zoneadd({ ...params, image: params.image},areaZoneId)
        .then((res) => {
          if (res.status === 200) {
            setShow(false);
            formik.resetForm();
            console.warn(formik.resetForm);
            setPage(true);
            // window.location.reload(true);
          } else {
            console.warn();
          }
        })
        .catch((res) => {
          console.warn();
          ErrorTost(res.message);
        });
    },
  });

  const getareadata = () => {
    areaalldata()
      .then((res) => {
        // console.warn("ssss",res.data.data);
        setData(res.data.data);
        setPage(false);
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  useEffect(() => {
    getareadata();
  }, [page]);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleSelect = (e) => {
    console.log(e);
    setValue(e);
  };
  // console.log('dropDwon values', value);
  return (
    <div>
      <div>
        <AddButton onClick={handleShow} />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>ADD Zone</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col>
                <Form.Label as="h6">Select Area</Form.Label>
                <Dropdown onSelect={handleSelect}>
                  <Dropdown.Toggle className="mb-3 mt-2">
                    {value !== "" ? value : "Select Areas"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu title={"Areas"}>
                    {data.length > 0 &&
                      data.map((item) => {
                        return (
                          <Dropdown.Item title="Select Areas" eventKey={item.title} key={"title"}>
                            {item.title}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col>
                <Form.Label as="h6">Title</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3 mt-3"
                  name={"title"}
                  placeholder="Enter your title"
                  value={formik?.values?.title}
                  onChange={formik.handleChange}
                />
                {formik.errors.title && formik.touched.title ? (
                  <p className="error">{formik.errors.title}</p>
                ) : null}
              </Col>
              <Col>
                <Form.Label as="h6">Subnetid</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3 mt-3"
                  name={"subnetid"}
                  placeholder="Enter your Subnetid"
                  value={formik?.values?.subnetid}
                  onChange={formik.handleChange}
                />
                {formik.errors.subnetid && formik.touched.subnetid ? (
                  <p className="error">{formik.errors.subnetid}</p>
                ) : null}{" "}
              </Col>
            </Row>

            <Form.Label as="h6">Image</Form.Label>
            <Form.Control
              type="file"
              className="mb-3 mt-3"
              name={"image"}
              placeholder="Enter your Image"
              value={formik?.file?.image}
              onChange={(e) =>
                formik.setFieldValue("image", e.currentTarget.files[0])
              }
            />
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <CustomButton onClick={handleClose} buttonText={"Close"} />
          <CustomButton
            onClick={formik.handleSubmit}
            buttonText={"ADD"}
            type={"submit"}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Model;
