import React, { useEffect, useState } from "react";
import {Col, Form, Row, Table } from 'react-bootstrap';
import * as yup from "yup";
import { useFormik } from "formik";
import EditButton from '../../editbutton/EditButton';
import DeleteButton from '../../deletebutton/DeleteButton';
import { dmxDelete } from "../../../services/deleteServices";
import { dmxadd } from "../../../services/postServices";
import { dmxdataByZoneId } from "../../../services/getServices";
import AddButton from "../../Add/AddButton";
import CancelButton from "../../Cancel/CancelButton";

const Dmx = ({zoneId = '', mapData = []}) => {
    const [data, setData] = useState(mapData);
    const [page, setPage] = useState(true);

    const initialValues = {
      title:"",
      deviceid: "",
      subnetid:"",
    };

    const validationSchema = yup.object({
        title: yup.string().min(5).required("Please Enter your zoneid"),
        deviceid: yup.number().min(5).required("Please Enter your deviceid"),
        subnetid: yup.number().min(5).required("Please Enter your Subnetid"),
    });

    const formik = useFormik({
        initialValues,
        enableReinitialize:data,
        validationSchema,
        onSubmit : (params) => {
            dmxadd({...params, zoneid: zoneId})
          .then((res) => {
            if (res.status === 200) {
              console.warn(res.data.data);
              formik.resetForm();
              setPage(true);
            } else {
              console.warn();
            }
          })
          .catch((err) => {
            console.warn("dddddddd", err);
          });
        }
    });
      const getDmxDataByZoneId = () => {
        dmxdataByZoneId(zoneId).then((res) => {
          setData(res.data.data);
          setPage(false);
        })
        .catch((err) => {
          console.warn(err);
        });
      };
      useEffect(() => {
        if(zoneId,page){
          getDmxDataByZoneId();
        } else if (page) {
        }
      }, [page, zoneId]);

      const cancelvalue = () => {
        formik.resetForm()
      }

      const handleDelete = (userid) =>{
        dmxDelete(userid);
        setPage(true);
      }
  return (
    <div>
        <Table striped hover responsive className="mt-5">
          <thead>
            <tr>
              <th>Title</th>
              <th>Device Id</th>
              <th>Subnet id</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr onSubmit={formik.handleSubmit}>
              <td>
                <Form.Control
                  type="text"
                  name={"title"}
                  placeholder="Enter your Title"
                  value={formik?.values?.title}
                  onChange={formik.handleChange}
                  style={{ width: "350px" }}
                />
                {formik.errors.title && formik.touched.title ? (
                  <p className="error">{formik.errors.title}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="text"
                  name={"deviceid"}
                  placeholder="Enter your Device ID"
                  value={formik?.values?.deviceid}
                  onChange={formik.handleChange}
                  style={{ width: "350px" }}
                />
                {formik.errors.deviceid && formik.touched.deviceid ? (
                  <p className="error">{formik.errors.deviceid}</p>
                ) : null}
              </td>
              <td>
                <Form.Control
                  type="number"
                  name={"subnetid"}
                  placeholder="Enter your subnetid"
                  value={formik?.values?.subnetid}
                  onChange={formik.handleChange}
                  style={{ width: "350px" }}
                />
                {formik.errors.subnetid && formik.touched.subnetid ? (
                  <p className="error">{formik.errors.subnetid}</p>
                ) : null}
              </td>
              <td>
              <div className="d-flex">
                    <AddButton type={"submit"} onClick={formik.handleSubmit} />
                    <CancelButton onClick={cancelvalue} />
                </div>
              </td>
            </tr>
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item.title}</td>
                    <td>{item.deviceid}</td>
                    <td>{item.subnetid}</td>
                    <td>
                      <Row sm={3} className="justify-content-center">
                        <Col>
                          <EditButton />
                        </Col>
                        <Col>
                          <DeleteButton  onClick={() => handleDelete(item._id)} />
                        </Col>
                      </Row>
                    </td>
                    <td></td>
                  </tr>
                );
              })}
          </tbody>
        </Table>

  </div>
  )
}

export default Dmx
