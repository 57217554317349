import React from 'react'
import "./maintitle.css"

const Maintitle = ({heading,textAlign}) => {
  return (
    <>
        <h1 className='maintitle' style={{textAlign:textAlign}}>{heading}</h1>
    </>
  )
}

export default Maintitle