import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";

const DeleteButton = ({ onClick }) => {
  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Delete</strong>
    </Tooltip>
  );
  return (
    // <button style={{backgroundColor:"white", border:"none"}}>
      <OverlayTrigger placement="bottom" overlay={tooltip} delayShow={50}>
        <AiOutlineDelete size={25} onClick={onClick} title="Delete" />
      </OverlayTrigger>
    // </button>
  );
};

export default DeleteButton;
