import React, { useState } from "react";
import {
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Nav,
  NavDropdown,
  Navbar,
  Row,
} from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { MdGroup, MdGroups2 } from "react-icons/md";
import { FaThLarge, FaThList } from "react-icons/fa";
import { BsArrowLeftSquareFill } from "react-icons/bs";
import { RiAdminFill, RiGroup2Fill } from "react-icons/ri";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import "./sidebar.css";

const NavSidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      {/* <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">Logo</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home" style={{flot : "left"}}>Home</Nav.Link>
              <Nav.Link href="#link">Link</Nav.Link>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>  
            </Nav>  
          </Navbar.Collapse> 
        </Container>
      </Navbar> */}
      <Row className="w-100">
        <Col
          sm={2}
          md={isOpen ? 4 : 1}
          xs={1}
          lg={isOpen ? 3 : 1}
          xl={isOpen ? 2 : 1}
        >
          <Sidebar
            width="200px"
            className="side"
            rootStyles={{
              minWidth: "50px",
              height: "130vh",
              width: isOpen ? "250px" : "80px",
            }}
          >
            <div className="d-flex mt-3">
              <h1
                style={{ display: isOpen ? "block" : "none" }}
                className="logo mx-4"
              >
                Logo
              </h1>
              <Link
                style={{ marginLeft: isOpen ? "75px" : "0px" }}
                className="bars"
              >
                <div className="d-flex mt-2">
                  <FaBars
                    onClick={toggle}
                    style={{ marginLeft: !isOpen ? "23px" : "0px" }}
                  />
                </div>
              </Link>
              <Link
                style={{ display: isOpen ? "block" : "none" }}
                className="iconbars"
              ></Link>
            </div>
            <div className="mt-3">
              <Menu>
                <Link to="/dashboard">
                  <MenuItem icon={<FaThLarge color="#4a2125" size={"22px"} />}>
                    <label>Dashboard</label>
                  </MenuItem>
                </Link>
                <ListGroup>
                  <SubMenu
                    label={<label>Table</label>}
                    icon={<FaThList color="#4a2125" size={"22px"} />}
                  >
                    <ListGroupItem className="p-0 li">
                      <Link to="/adminuser">
                        <MenuItem
                          // icon={<FaUserAlt color="#4a2125" size={"22px"}/>}
                          icon={<RiAdminFill color="#4a2125" size={"25px"} />}
                        >
                          <label>Admin User</label>
                        </MenuItem>
                      </Link>
                    </ListGroupItem>
                    <ListGroupItem className="p-0 li">
                      <Link to="/distributor">
                        <MenuItem
                          icon={<RiGroup2Fill color="#4a2125" size={"25px"} />}
                        >
                          <label>Distributor</label>
                        </MenuItem>
                      </Link>
                    </ListGroupItem>
                    <ListGroupItem className="p-0 li">
                      <Link to="/distributoruser">
                        <MenuItem
                          // icon={<FaUserFriends color="#4a2125"  size={"22px"} />}
                          icon={<MdGroup color="#4a2125" size={"25px"} />}
                        >
                          <label>Distributor User</label>
                        </MenuItem>
                      </Link>
                    </ListGroupItem>
                    <ListGroupItem className="p-0 li">
                      <Link to="/customeruser">
                        <MenuItem
                          // icon={<FaUserFriends color="#4a2125"  size={"22px"} />}
                          icon={<MdGroups2 color="#4a2125" size={"30px"} />}
                        >
                          <label>Customer</label>
                        </MenuItem>
                      </Link>
                    </ListGroupItem>
                  </SubMenu>
                  <Menu>
                    <Link to="/logout">
                      <MenuItem
                        icon={
                          <BsArrowLeftSquareFill
                            color="#4a2125"
                            size={"22px"}
                          />
                        }
                      >
                        <label>Logout</label>
                      </MenuItem>
                    </Link>
                  </Menu>
                </ListGroup>
              </Menu>
            </div>
          </Sidebar>
        </Col>
        <Col 
        md={isOpen ? 8 : 11} 
        sm={10} 
        xs={11} 
        lg={isOpen ? 9 : 11} 
        xl={isOpen ? 10 : 11} 
        className="main"
        >
          {children}
        </Col>
      </Row>
    </>
  );
};

export default NavSidebar;
